import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    FormHelperText,
    FormErrorMessage
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import { CBColors } from "../../config";
  import { BsGoogle } from "react-icons/bs";
  import { useForm } from "react-hook-form";
import { render } from "@testing-library/react";

  const InstantContractModal = ({ isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState("Sign In");

    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });
  
    const onSubmit = (data) => {
      console.log(data);
      setIsSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent zIndex="9999" borderRadius="20px" bg="white">
          <ModalHeader>{activeTab}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Tabs isFitted variant="enclosed">
                <TabList mb="1em">
                  <Tab onClick={() => setActiveTab("Sign In")}>Sign In</Tab>
                  <Tab onClick={() => setActiveTab("Sign Up")}>Sign Up</Tab>
                </TabList>
                <TabPanels>
                  {/* siginin */}
                  <TabPanel>
                  <Stack spacing={4}>
                  <Text fontSize={"12px"} color={"#828282"}>
                        through social media recommended
                      </Text>
                      <Stack direction={"row"}>
                        <Button
                          flex={1}
                          fontSize={"sm"}
                          rounded={"full"}
                          _focus={{
                            bg: "gray.200",
                          }}
                          colorScheme={CBColors.red}
                        >
                          <BsGoogle />
                        </Button>
                      </Stack>
                      <Text fontSize={"16px"} color={"#828282"} align={"center"}>
                        or
                      </Text>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                          id="userId"
                          borderRadius={20}
                          backgroundColor="#FFF9F9"
                        >
                          <Input
                            variant="flushed"
                            placeholder="Email / Phone"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{ borderColor: "transparent",
                            boxShadow: "none" }}
                            borderColor="transparent"
                          />
                        </FormControl>
  
                        <FormControl
                          id="otp"
                          borderRadius={20}
                          backgroundColor="#FFF9F9"
                          mt={"12px"}
                        >
                          <Input
                            variant="flushed"
                            placeholder="OTP"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{ borderColor: "transparent",
                            boxShadow: "none" }}
                            borderColor="transparent"
                          />
                          
                        </FormControl>
                          </form>
                      <Button alignSelf={"center"} color={CBColors.textBox} bg={CBColors.wait} borderRadius={"20px"} w={"131px"} >Sign In</Button>
                    </Stack>
                  </TabPanel>
                  {/* siginup */}
                  <TabPanel>
                  <Stack spacing={4}>
                      <Text fontSize={"12px"} color={"#828282"}>
                        through social media recommended
                      </Text>
                      <Stack direction={"row"}>
                        <Button
                          flex={1}
                          fontSize={"sm"}
                          rounded={"full"}
                          _focus={{
                            bg: "gray.200",
                          }}
                          colorScheme={CBColors.red}
                        >
                          <BsGoogle />
                        </Button>
                      </Stack>
                      <Text fontSize={"16px"} color={"#828282"} align={"center"}>
                        or
                      </Text>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                          id="name"
                          borderRadius={20}
                          backgroundColor="#FFF9F9"
                        >
                          <Input
                            variant="flushed"
                            placeholder="Name"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{ borderColor: "transparent",
                            boxShadow: "none" }}
                            borderColor="transparent"
                          />
                        </FormControl>
  
                        <FormControl
                          id="email"
                          borderRadius={20}
                          backgroundColor="#FFF9F9"
                          mt={"12px"}
                        >
                          <Input
                            variant="flushed"
                            placeholder="Email"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{ borderColor: "transparent",
                            boxShadow: "none" }}
                            borderColor="transparent"
                          />
                          
                        </FormControl>
                        <FormControl isInvalid={errors.phoneNumber}  id="phone"
                          borderRadius={20}
                                mt={"12px"}
                          backgroundColor="#FFF9F9">
                          <Input
                            type="tel"
                            id="phoneNumber"
                            variant="flushed"
                            placeholder="Phone"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{ borderColor: "transparent",
                            boxShadow: "none" }}
                            borderColor="transparent"
                            {...register("phoneNumber", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^[0-9\b]+$/,
                                message: "Phone number must only contain numbers",
                              },
                              minLength: {
                                value: 10,
                                message:
                                  "Phone number must be at least 10 digits long",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Phone number must be at most 10 digits long",
                              },
                            })}
                          />
                          <FormErrorMessage fontSize={"10px"}>
                            {errors.phoneNumber?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <Text fontSize={"10px"} color={"#828282"}>
                        *please verify details, further communications will be made through them
                      </Text>
                      </form>
                        <Button alignSelf={"center"} color={CBColors.textBox} bg={CBColors.wait} borderRadius={"20px"} w={"131px"} >Sign Up</Button>
                    </Stack>
                  </TabPanel>
                   
                </TabPanels>
              </Tabs>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

  }

  export default InstantContractModal;