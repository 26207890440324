import React, { useEffect, useState } from 'react'

const Events = () => {
    const [allData, setAllData] = useState([])

    return (
        <div>
            {/* {
              allData.length && allData[0].cIds.map((eachRow, index) => (
                <div>
                    <div>{eachRow}</div>
                </div>
              ))
            } */}
        </div>
      )
}


export default Events