/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  FormHelperText,
  FormErrorMessage,
  useToast,
  CircularProgress,
  Flex,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { CBColors } from "../../config";
import { BsGoogle } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";
import { authSignin, authGoogle, auth } from "../../config";
import { GoogleLogin } from "react-google-login";
import { Context } from "../../provider/context";

const SignInSignUpModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("Sign In");

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const toast = useToast();
  const { loginStatus, appliedData, createdData, appliedModalStatus,appliedModelText,appliedModelLink,userInfo } =
    useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [isModalAppliedOpen, setisModalAppliedOpen] = appliedModalStatus;
  const [modalAppliedText, setModalAppliedText] = appliedModelText;
  const [modalAppliedLink, setModalAppliedLink] = appliedModelLink;
  const [userIdDB, setUserIdDB]= userInfo;

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const sendOtp = async () => {
    if (userId === "") {
      toast({
        title: "Error",
        description: "Please enter your email or phone number",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      setIsLoading(true);
      await axios
        .post(authSignin, {
          userId: userId,
        })
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: res.data.message,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setIsOtpSent(true);
            setIsLoading(false);
          } else if (res.status === 404) {
            toast({
              title: res.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          } else {
            toast({
              title: res.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast({
              title: err.response.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          } else {
            toast({
              description: "Something went wrong. Please try again later.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const signin = async () => {
    if (otp === "") {
      toast({
        title: "Error",
        description: "Please enter otp",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    } else {
      setIsLoading(true);
      await axios
        .post(authSignin, {
          userId: userId,
          otp: otp,
        })
        .then((res) => {
          if (res.status === 200 && !res.data.message.includes("Wrong")) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem(
              "applied",
              JSON.stringify(res.data.data.leadsApplied)
            );
            localStorage.setItem(
              "created",
              JSON.stringify(res.data.data.leadsCreated)
            );
            localStorage.setItem("userId", res.data.data._id);
            setLoggedIn(true);
            setApplied(res.data.data.leadsApplied);
            setCreated(res.data.data.leadsCreated);
            setUserIdDB(res.data.data._id);
            setIsLoading(false);
            toast({
              title: res.data.message,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
          } else {
            setIsLoading(false);
            toast({
              title: res.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIsLoading(false);
            toast({
              title: err.response.data.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else {
            setIsLoading(false);
            toast({
              description: "Something went wrong. Please try again later.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
    }
  };

  const signUP = async () => {
    if (email !== "") {
      if (!/\S+@\S+\.\S+/.test(email)) {
        return toast({
          description: "Please enter a valid email",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      return toast({
        description: "Please enter your email",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (name === "") {
      return toast({
        description: "Please enter your name",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (phone !== "") {
      const phonePattern = /^[0-9\b]+$/;
      const phoneMinLength = 10;
      const phoneMaxLength = 10;
      if (!phonePattern.test(phone)) {
        return toast({
          description: "Phone number must only contain numbers",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (phone.length < phoneMinLength) {
        return toast({
          description: "Phone number must be at least 10 digits long",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (phone.length > phoneMaxLength) {
        return toast({
          description: "Phone number must be at most 10 digits long",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      return toast({
        description: "Please enter your phone",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(true);
    await axios
      .post(auth, {
        name: name,
        email: email,
        phone: parseInt(phone),
        countryCode: "+91",
        roles: {
          id: 1,
          role: "user",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem(
            "applied",
            JSON.stringify(res.data.data.leadsApplied)
          );
          localStorage.setItem(
            "created",
            JSON.stringify(res.data.data.leadsCreated)
          );
          setLoggedIn(true);
          setApplied(res.data.data.leadsApplied);
          setCreated(res.data.data.leadsCreated);
          onClose();
          setIsLoading(false);
        } else {
          toast({
            title: res.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        } else {
          toast({
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      });
  };

  const googleAuth = async (res) => {
    console.log(JSON.stringify(res));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent zIndex="9999" borderRadius="20px" bg="white">
        <ModalHeader>{activeTab}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab onClick={() => setActiveTab("Sign In")}>Sign In</Tab>
                <Tab onClick={() => setActiveTab("Sign Up")}>Sign Up</Tab>
              </TabList>
              <TabPanels>
                {/* siginin */}
                <TabPanel>
                  <Stack spacing={4}>
                    <Text fontSize={"12px"} color={"#828282"}>
                      through social media recommended
                    </Text>
                    <Stack direction={"row"}>
                      <GoogleLogin
                        clientId="445887226440-kl45f95bjggkrcdverpoqmdkurerjje2.apps.googleusercontent.com"
                        onSuccess={googleAuth}
                        onFailure={(err) => {
                          toast({
                            description:
                              "Something went wrong. Please try again later.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }}
                        cookiePolicy={"single_host_origin"}
                        render={(renderProps) => (
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            rounded={"full"}
                            _focus={{
                              bg: "gray.200",
                            }}
                            colorScheme={CBColors.red}
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <BsGoogle />
                          </Button>
                        )}
                      >
                        <BsGoogle />
                      </GoogleLogin>
                    </Stack>
                    <Text fontSize={"16px"} color={"#828282"} align={"center"}>
                      or
                    </Text>
                    <form>
                      <FormControl
                        id="userId"
                        borderRadius={20}
                        backgroundColor="#FFF9F9"
                      >
                        <Input
                          variant="flushed"
                          placeholder="Email / Phone"
                          _placeholder={{ color: "#A9A9A9" }}
                          px={4}
                          py={2}
                          isDisabled={isOtpSent}
                          _focus={{
                            borderColor: "transparent",
                            boxShadow: "none",
                          }}
                          borderColor="transparent"
                          onChange={handleUserIdChange}
                        />
                      </FormControl>
                      {isOtpSent && (
                        <FormControl
                          id="otp"
                          borderRadius={20}
                          backgroundColor="#FFF9F9"
                          mt={"12px"}
                          onChange={handleOtpChange}
                        >
                          <Input
                            variant="flushed"
                            placeholder="OTP"
                            _placeholder={{ color: "#A9A9A9" }}
                            px={4}
                            py={2}
                            _focus={{
                              borderColor: "transparent",
                              boxShadow: "none",
                            }}
                            borderColor="transparent"
                          />
                        </FormControl>
                      )}
                    </form>
                    {isOtpSent ? (
                      <Flex justifyContent={"center"} alignItems={"center"}>
                        {isLoading ? (
                          <CircularProgress
                            isIndeterminate
                            color="blue.500"
                            display={"flex"}
                            justifyContent={"center"}
                          />
                        ) : (
                          <Button
                            alignSelf={"center"}
                            color={CBColors.textBox}
                            bg={CBColors.wait}
                            borderRadius={"20px"}
                            w={"131px"}
                            onClick={signin}
                          >
                            Sign In
                          </Button>
                        )}
                      </Flex>
                    ) : (
                      <Flex justifyContent={"center"} alignItems={"center"}>
                        {isLoading ? (
                          <CircularProgress
                            isIndeterminate
                            color="blue.500"
                            display={"flex"}
                            justifyContent={"center"}
                          />
                        ) : (
                          <Button
                            alignSelf={"center"}
                            color={CBColors.textBox}
                            bg={CBColors.wait}
                            borderRadius={"20px"}
                            w={"131px"}
                            onClick={sendOtp}
                          >
                            Send OTP
                          </Button>
                        )}
                      </Flex>
                    )}
                  </Stack>
                </TabPanel>
                {/* siginup */}
                <TabPanel>
                  <Stack spacing={4}>
                    <Text fontSize={"12px"} color={"#828282"}>
                      through social media recommended
                    </Text>
                    <Stack direction={"row"}>
                      <GoogleLogin
                        clientId="445887226440-kl45f95bjggkrcdverpoqmdkurerjje2.apps.googleusercontent.com"
                        onSuccess={googleAuth}
                        onFailure={(err) => {
                          toast({
                            description:
                              "Something went wrong. Please try again later.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }}
                        cookiePolicy={"single_host_origin"}
                        render={(renderProps) => (
                          <Button
                            flex={1}
                            fontSize={"sm"}
                            rounded={"full"}
                            _focus={{
                              bg: "gray.200",
                            }}
                            colorScheme={CBColors.red}
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <BsGoogle />
                          </Button>
                        )}
                      >
                        <BsGoogle />
                      </GoogleLogin>
                    </Stack>
                    <Text fontSize={"16px"} color={"#828282"} align={"center"}>
                      or
                    </Text>
                    <form>
                      <FormControl
                        id="name"
                        onChange={handleNameChange}
                        borderRadius={20}
                        backgroundColor="#FFF9F9"
                      >
                        <Input
                          variant="flushed"
                          placeholder="Name"
                          _placeholder={{ color: "#A9A9A9" }}
                          px={4}
                          py={2}
                          _focus={{
                            borderColor: "transparent",
                            boxShadow: "none",
                          }}
                          borderColor="transparent"
                        />
                      </FormControl>

                      <FormControl
                        id="email"
                        onChange={handleEmailChange}
                        borderRadius={20}
                        backgroundColor="#FFF9F9"
                        mt={"12px"}
                      >
                        <Input
                          variant="flushed"
                          placeholder="Email"
                          _placeholder={{ color: "#A9A9A9" }}
                          px={4}
                          py={2}
                          _focus={{
                            borderColor: "transparent",
                            boxShadow: "none",
                          }}
                          borderColor="transparent"
                        />
                      </FormControl>
                      <FormControl
                        id="phone"
                        borderRadius={20}
                        mt={"12px"}
                        backgroundColor="#FFF9F9"
                      >
                        <Input
                          type="tel"
                          id="phoneNumber"
                          onChange={handlePhoneChange}
                          variant="flushed"
                          placeholder="Phone"
                          _placeholder={{ color: "#A9A9A9" }}
                          px={4}
                          py={2}
                          _focus={{
                            borderColor: "transparent",
                            boxShadow: "none",
                          }}
                          borderColor="transparent"
                        />
                      </FormControl>
                      <Text fontSize={"10px"} color={"#828282"}>
                        *please verify details, further communications will be
                        made through them
                      </Text>
                    </form>
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        color="blue.500"
                        display={"flex"}
                        justifyContent={"center"}
                      />
                    ) : (
                      <Button
                        alignSelf={"center"}
                        color={CBColors.textBox}
                        bg={CBColors.wait}
                        onClick={signUP}
                        borderRadius={"20px"}
                        w={"131px"}
                      >
                        Sign Up
                      </Button>
                    )}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignInSignUpModal;
