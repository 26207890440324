import { Helmet } from "react-helmet";
import { Flex, Text } from "@chakra-ui/react";

const UserAggrement = () => {
  return (
    <Flex flexDirection={"column"}>
        <Helmet>
          <title>Terms & Conditions | Crewbella</title>
        </Helmet>
      <Text px={"40px"} fontSize={"20px"} fontWeight={"bold"}>
        Terms & Conditions
      </Text>
      <Flex px={"50px"}>
        <div
          className="container"
          style={{
           
          }}
        >
          <h3 className="c3 c11" id="h.94xccwxm8rq9">
            <span className="c17">USER AGREEMENT</span>
          </h3>
          <h3 className="c3 c18" id="h.ft9ownic5n3d">
            <span className="c17">Terms and Conditions</span>
          </h3>
          <p className="c5 c3">
            <span className="c1">
              Welcome to CREWBELLA.com, (hereinafter referred to as the
              &quot;Site&quot; or as &quot;CREWBELLA&quot;
              interchangeably).These terms and conditions of use
              (&quot;Terms&quot;) constitute a legal agreement between you and
              CREWBELLA. Use of this Site constitutes your unconditional
              acceptance of these Terms herein and by all terms, policies and
              guidelines incorporated by reference. These Terms apply to your
              use of this Site, and do not alter in any way the terms or
              conditions of any other agreement you may have with CREWBELLA, its
              subsidiaries or affiliates. If you are using the Site on behalf of
              any entity, you further represent and warrant that you are
              authorized to accept these Terms on such entity&#39;s behalf, and
              that such entity agrees to indemnify CREWBELLA for violations of
              these Terms. If you do not agree with these terms, please do not
              use this Site.
            </span>
          </p>
          <p className="c5 c3">
            <span className="c1">
              Any individual and organisation wanting to make use of Site to
              post an internships/full time-part time jobs/contractual work
              (hereinafter referred to as the &quot;Client Post&quot;) for self
              or for an organisation he/she represents is referred to as
              &quot;Client&quot; or &quot;Clients&quot; as context may demand.
            </span>
          </p>
          <p className="c5 c3">
            <span className="c1">
              Any individual wanting to make use of Site to apply to Jobs listed
              on CREWBELLA is referred to as &quot;Crew&quot; as context may
              demand.
            </span>
          </p>
          <h4 className="c8 c3" id="h.y0msrrgdhyqw">
            <span className="c0">Registration Data and Account Security</span>
          </h4>
          <p className="c20 c3">
            <span className="c1">
              In consideration of your use of the Site, you agree to: (a)
              provide accurate, current and complete information about you as
              may be prompted by any registration forms on the Site
              (&quot;Registration Data&quot;); (b) maintain and promptly update
              the Registration Data, and any other information you provide to
              CREWBELLA, to keep it accurate, current and complete; (c) maintain
              the security of your password and identification; (d) notify
              CREWBELLA immediately of any unauthorized use of your account or
              any other breach of security; (e) accept all responsibility for
              any and all activities that occur under your account; and (f)
              accept all risks of unauthorized access to the Registration Data
              and any other information you provide to CREWBELLA. Please refer
              to our Privacy Policy for more details on how we collect, store,
              use, and retain your data on the Site.
            </span>
          </p>
          <h4 className="c8 c3" id="h.sas0893b6dbt">
            <span className="c0">
              Most Important Terms &amp; Conditions for CLIENTS
            </span>
          </h4>
          <p className="c3 c5">
            <span className="c1">
              These Terms, specifically for Clients, are in addition to the
              other generic Terms &amp; Conditions listed on the page which are
              common to all the users of the site and which you are required to
              go through. Any violation of these Terms may result in, but not
              limited to, an Client&#39;s Job being declined for publication on
              CREWBELLA and/or his/her account being permanently blocked and/or
              appropriate legal action being initiated against him/her by
              CREWBELLA.
            </span>
          </p>
          <p className="c15 c3">
            <span className="c1"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">Job Posting T&amp;Cs:</span>
          </p>
          <ol className="c13 lst-kix_f7sk0nl13sbk-0 start" start="1">
            <li className="c2 li-bullet-0">
              <span className="c1">
                It is your responsibility to ensure that you are authorized to
                post Jobs on your organization&#39;s behalf. Any dispute or
                legal claim arising out of unauthorized posting would be solely
                your liability and you indemnify CREWBELLA and CREWBELLA of any
                and all possible consequences of such actions.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                If you upload your organization&#39;s logo while posting your
                Job or creating/updating your company profile, you are
                authorizing CREWBELLA to display it on our website along with
                Job post or in the list of our clients. It is your
                responsibility to ensure that you are duly authorized to share
                your organization logo with 3rd parties and allow them to use
                it.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                CREWBELLA reserves the right to publish the Job post of Clients
                on its social media handles and job board partners like
                LinkedIn, Indeed etc., and in newspapers in order to increase
                the visibility of the Job post.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                You must provide accurate and complete details about the
                organization and the Job. Any act of misinformation or hiding
                material information would result in your account being
                permanently blocked or any other suitable action as deemed fit
                by CREWBELLA.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                You may be asked for documents in the name of your company for
                our first-time user authentication process. This information
                will only be used for authentication purposes and will not be
                used anywhere else in any form.
              </span>
            </li>
          </ol>
          <p className="c15 c3">
            <span className="c1"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">CREWBELLA does NOT allow:</span>
          </p>
          <ol className="c13 lst-kix_12hspojxp5mo-0 start" start="1">
            <li className="c2 li-bullet-0">
              <span className="c1">
                Unpaid internships and Free Collaborations.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Training program and any such program where Crews are expected
                to pay a security deposit or an admission fee. To charge money
                in any form (including test fee, security deposit, documentation
                fee etc.) from the Crews for offering Job is strictly
                prohibited.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Modelling Jobs and Acting Jobs, however jobs to hire
                &ldquo;Casting Director&rdquo; are allowed.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Network Level Marketing (NLM) jobs/internships where
                Crews/individuals have to use their personal connections and
                personal social media accounts to promote a business.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Any Job where there is a possibility of an Crew consuming
                alcohol or smoking or inducing others to do so, as the majority
                of our users are underage.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Individuals or organisations promoting explicit religious
                content, a particular religious personality or a sect etc. are
                not allowed to post Jobs on CREWBELLA.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Businesses promoting or dealing in gambling, porn, and other
                prohibited/illegal/age inappropriate activities.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Third party Job posting. You can post Jobs only for the
                organization that you directly work for. Recruiting agencies or
                any third party agencies are not authorized to post Jobs on
                their clients&#39; behalf.
              </span>
            </li>
          </ol>
          <p className="c15 c3">
            <span className="c1"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">Hiring T&amp;Cs:</span>
          </p>
          <ol className="c13 lst-kix_qk54rani1ddz-0 start" start="1">
            <li className="c2 li-bullet-0">
              <span className="c1">
                It is your responsibility to ensure that there is no material
                difference between the Job details that are advertised on
                CREWBELLA and the details that are communicated to Crews later
                in the selection process (pre-interview, in offer letter etc.).
                Any &lsquo;negative&#39; material difference (such as offered
                stipend being lower than what was advertised) will result in
                suitable action being taken by CREWBELLA against you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Any assignment(s) that you may give to Crews to assess their
                suitability for the Job should be fair and relevant to the Job
                profile. Any attempt to get work done for free from Crews
                disguised as assessment or to get them to download or generate
                downloads for your app or get likes/shares/comments etc. for
                your social media handles only for the purpose of getting free
                publicity disguised as an assignment are strictly prohibited and
                would invite suitable actions against you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                All your communications with Crews (through CREWBELLA Chat or
                otherwise) should be professional and must not contain any
                obscene or offensive content.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                Once you hire any Crew(s) for your Job, you must provide them
                with an agreement made on CREWBELLA clearly detailing all the
                important details of the Job (such as role &amp;
                responsibilities, remuneration, and payment mechanism) and
                complete address and contact details of your organisation.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                You must pay the promised remuneration to the selected Crews in
                a timely manner as per agreed terms and conditions in the
                agreement through crewbella portal. Non payment of the
                remuneration or delayed payment of the remuneration is strictly
                prohibited and would invite suitable action against you.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                You must respond within 72 working hours to any Crew complaints
                regarding your Job that we may bring to your notice. Failure to
                do so may result in a temporary or permanent suspension of your
                CREWBELLA account depending on the nature of the complaint.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                If you come across any suspicious activity, content or behaviour
                on CREWBELLA by an Crew or another user, you must report it to
                CREWBELLA immediately so that necessary actions can be taken.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                While we put our best efforts to reach out to the best talent
                available in the country, posting your Job on CREWBELLA does not
                guarantee hiring.
              </span>
            </li>
          </ol>
          <p className="c15 c3">
            <span className="c1"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">Crew data usage T&amp;Cs:</span>
          </p>
          <ol className="c13 lst-kix_n7v4r43ubceh-0 start" start="1">
            <li className="c2 li-bullet-0">
              <span className="c1">
                You can use the Crews&#39; data that you receive for your Job
                solely for the purpose of hiring. Any attempt to send any other
                communication (promotional content for example) to the Crews or
                any other usage of the data is strictly prohibited.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c1">
                You are strictly prohibited from transferring/selling/sharing
                Crews&#39; data, for free or for a fee, to any other entity. Any
                such attempt would result in your account on CREWBELLA being
                permanently blocked and would be liable for legal action against
                you and your organization.
              </span>
            </li>
          </ol>
          <p className="c5 c3">
            <span className="c1">Payment &amp; refund T&amp;Cs:</span>
          </p>
          <ol className="c13 lst-kix_us2j28se0yvs-0 start" start="1">
            <li className="c2 li-bullet-0">
              <span className="c1">
                Payments for the services offered by CREWBELLA shall be on a
                100% advance basis.
              </span>
            </li>
            <li className="c10 li-bullet-0">
              <span className="c1">
                Refund if any will be as per our Refund Policy.
              </span>
            </li>
            <li className="c10 li-bullet-0">
              <span className="c1">
                CREWBELLA offers no guarantees whatsoever for the accuracy or
                timeliness of the refunds reaching the Clients card/bank
                accounts.
              </span>
            </li>
          </ol>
          <h4 className="c3 c8" id="h.mcwmlg5ys74o">
            <span className="c0">
              Most Important Terms &amp; Conditions for CREW.
            </span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              These Terms, specifically for CREW, are in addition to the other
              generic Terms &amp; Conditions listed on the page which are common
              to all the users of the site and which you are required to go
              through. Any violation of these T&amp;Cs may result in, but not
              limited to, a crew&#39;s Job application being declined and/or
              his/her account being permanently blocked and/or suitable legal
              action being initiated against him/her by CREWBELLA.
            </span>
          </p>
          <ol className="c13 lst-kix_svrjj4fh0k4w-0 start" start="1">
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                If you have applied for a Client Post on CREWBELLA and receive a
                communication from CREWBELLA or Client regarding your
                application, you must respond to it with-in 2-8 hours in
                QUICKBOOK.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Once you accept a Job offer, you must make every possible effort
                to join and start the Job on time. To not show up or to decline
                an earlier accepted Job offer at the last moment, or to go
                incommunicado creates a very poor impression of you in front of
                the Client and reduces the credibility of the Crew community at
                large. Any such behaviour will not only result in your account
                being blocked on CREWBELLA but we will also report it to your
                profile wherever applicable.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                You must provide accurate and complete information at the time
                of Job application or creating an account on CREWBELLA &ndash;
                any misrepresentation of information, or hiding of material
                information, or impersonation would result in your account being
                blocked on the site and you being reported to your local
                administration wherever applicable.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                You must pay attention to a Job&#39;s complete details (profile,
                location, remuneration, skills required, etc.) before applying
                for it. Irrelevant applications will result in your account
                being penalized (we may stop you from applying to future Jobs or
                block your account all together)
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                You are strictly prohibited from transferring/selling/sharing
                Clients&#39; data (contact details etc.), for free or for a fee,
                with any other entity. Any such attempt would result in your
                account on CREWBELLA being permanently blocked and would be
                liable for legal action against you.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                CREWBELLA community is a community of sincere users who are
                known for and are expected to demonstrate high standards of
                professionalism in their dealings with other users and Clients
                &ndash; you are required to maintain these standards and decorum
                of the community. All your communications with other users of
                CREWBELLA (through CREWBELLA Chat or otherwise) should be
                professional and must not contain any obscene or offensive
                content.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                You agree to have gone through the &lsquo;Safety Tips for
                Crews&#39; section of these Terms and have understood the same.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                While it is our endeavor to provide you with accurate and
                reliable Job information; it is always possible for an error to
                occur. Hence, you must conduct your own due diligence and
                research about a Client or organisation before starting a Job
                and take full responsibility for the same. You further agree to
                have read and understood the &lsquo;Disclaimers&#39; section of
                these Terms and explicitly and specifically agree to it.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                If you come across any suspicious activity, content or behaviour
                on CREWBELLA by a Client or another user, you must report it to
                CREWBELLA immediately so that necessary actions can be taken
                &ndash; this is both your duty and obligation.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                While we make our best efforts to bring you the best Job
                opportunities possible, CREWBELLA does not guarantee a Job.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Upon registration, your registered email id and phone number
                will be automatically subscribed to receive email and SMS
                notifications from CREWBELLA. You may opt out of it anytime.
              </span>
            </li>
          </ol>
          <h4 className="c8 c3" id="h.6ulvfdfqvomr">
            <span className="c0">Safety Tips for Crews</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              It is our endeavor to provide all our users with a safe and hassle
              free experience on CREWBELLA. Accordingly, we would like to make
              Crews aware of following safety tips for them.
            </span>
          </p>
          <p className="c15">
            <span className="c4"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">CREWBELLA does NOT allow Clients to:</span>
          </p>
          <ol className="c13 lst-kix_3xbideef26j4-0 start" start="1">
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Offer unpaid Jobs, unless explicitly mentioned so in the Job
                post.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Post training program and any such program where Crews are
                expected to pay a security deposit or an admission fee.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                To charge money in any form (including test fee, security
                deposit, documentation fee etc.) from the Crews for offering
                Job. This is strictly prohibited.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">Offer modelling and acting Jobs from.</span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Offer Network Level Marketing (NLM) jobs/internships where
                Crews/individuals have to use their personal connections and
                personal social media accounts to promote a business.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Offer any Job where there is a possibility for an Crew consuming
                alcohol or smoking or inducing others to do so, as the majority
                of our users are underage.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Offer Jobs from individuals or organisations promoting explicit
                religious content, a particular religious personality, or a sect
                etc.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Offer Jobs at businesses promoting or dealing in gambling, porn,
                and other prohibited/illegal/age inappropriate activities.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Make a third party Job posting. A Client can post Jobs only for
                the organization that he/she directly works for. Recruiting
                agencies or any third party agencies are not authorized to post
                Jobs on their clients&#39; behalf.
              </span>
            </li>
          </ol>
          <p className="c15">
            <span className="c4"></span>
          </p>
          <p className="c5 c3">
            <span className="c1">
              Further, it is mandatory for Clients to ensure that :
            </span>
          </p>
          <ol className="c13 lst-kix_jnuemv2gvnmq-0 start" start="1">
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Job details communicated to Crews at any stage are the same as
                advertised on CREWBELLA and there is no negative material
                difference (Ex. - offering lower or no stipend than what was
                advertised on CREWBELLA).
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Any assignment(s) that they may give to Crews to assess their
                suitability for the Job should be fair and relevant to the Job
                profile. Any attempt to get work done for free from Crews
                disguised as assessment or to get them to download or generate
                downloads for an app or get likes/shares/comments etc. for
                Client&#39;s social media handles only for the purpose of
                getting free publicity disguised as an assignment are strictly
                prohibited.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                All their communications with Crews (through CREWBELLA Chat or
                otherwise) are professional and must not contain any obscene or
                offensive content.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                Once they hire any Crew(s) for their Job, they must provide them
                with an agreement on CREWBELLA clearly detailing all the
                important details of the Job (such as role &amp;
                responsibilities, remuneration, and payment mechanism) and
                complete address and contact details of Client.
              </span>
            </li>
            <li className="c2 c3 li-bullet-0">
              <span className="c1">
                They pay the promised remuneration to the selected Crew(s) in a
                timely manner as per agreed terms and conditions of the Job as
                detailed in the agreement on CREWBELLA payment portal.
              </span>
            </li>
          </ol>
          <p className="c20 c3">
            <span className="c1">
              If you come across any example of any Client violating these
              guidelines, you must report it to CREWBELLA using the Help Center
              so that we can investigate and take appropriate actions.
            </span>
          </p>
          <h4 className="c8 c3" id="h.9zxksk3jhsy5">
            <span className="c0">Disclaimers</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              This Site and its content are provided &quot;as is&quot; and
              CREWBELLA and its directors, employees, content providers, agents
              and affiliates exclude, to the fullest extent permitted by
              applicable law, any warranty, express or implied, including,
              without limitation, any implied warranties of merchantability,
              satisfactory quality or fitness for a particular purpose.
              CREWBELLA will not be liable for any damages of any kind arising
              from the use of this site. The functions embodied on or in the
              materials of this site are not warranted to be uninterrupted or
              without error. You, not CREWBELLA and/or CREWBELLA, assume the
              entire cost of all necessary care or correction due to your use of
              this site or content. CREWBELLA makes no warranty that the site or
              the content is free from infection by viruses or anything else
              that has contaminating or destructive properties. CREWBELLA uses
              reasonable efforts to ensure the accuracy, correctness and
              reliability of the Content, but we make no representations or
              warranties for the same. Images of people or places displayed on
              the Site are either the property of, or used with permission by,
              CREWBELLA or third parties. The use of these images by you, or
              anyone else authorized by you, is prohibited unless specifically
              permitted by these Terms or specific permission provided elsewhere
              on the Site or by separate license or agreement. Any unauthorized
              use of these images may violate copyright laws, trademark laws,
              the laws of privacy and publicity, and other applicable laws.
            </span>
          </p>
          <h4 className="c8 c3" id="h.r9ghrogb27iy">
            <span className="c0">Rights to Website and Contents thereof</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              This Site is owned and operated by CREWBELLA. All the content
              featured or displayed on this Site, including, but not limited to,
              text, graphics, data, images(photographic and moving),
              illustrations, software and selection and arrangement thereof
              (&quot;Content&quot;), is owned by CREWBELLA. All elements of this
              Site, including, but not limited to, the general design and the
              Content thereof, are protected by copyright, moral rights,
              trademark and other laws related to the protection of intellectual
              property rights. Except for, as explicitly permitted under this,
              or other written license or agreement with CREWBELLA, no portion
              or element of this Site or its content may be copied or
              retransmitted via any means. All related rights shall remain the
              exclusive property of CREWBELLA, its licensors or its third-party
              image partners.
            </span>
          </p>
          <h4 className="c8 c3" id="h.dzwdkkgxexqz">
            <span className="c0">Using the Website</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              This Site and its Content are intended for Users of CREWBELLA.com,
              the division of CREWBELLA. You may not use this Site or the
              content for any purpose, which is not related to your business
              with CREWBELLA.com. Any use of the Content, this Site or any of
              its functionality for a purpose not permitted by these Terms is
              grounds for the immediate revocation of any usernames, pass codes
              or other permissions that may have been granted by CREWBELLA.com
              on behalf of CREWBELLA for use of this Site. You are specifically
              prohibited from: (i) downloading, copying, or retransmitting any
              or all of the Site or the Content without, or in violation of, a
              written license or agreement with CREWBELLA; (ii) using any data
              mining or similar data gathering or extraction methods; (iii)
              manipulating or otherwise displaying the site or the content by
              using, framing or similar navigational technology; (iv)
              registering, subscribing, unsubscribing, or attempting to
              register, subscribe any party for any the site if you are not
              expressly authorized by such party to do so; and (v) using the
              Site or Content other than for its intended purpose. Such
              unauthorized use may also violate applicable laws including
              without limitation copyright and trademark laws, the laws of
              privacy and publicity, and applicable communications regulations
              and statutes. You represent and warrant that you will comply with
              all applicable regulations, including, without limitation, those
              relating to the internet, data, e-mail, privacy and the
              transmission of technical data exported from India or the Country
              in which you reside.
            </span>
          </p>
          <h4 className="c8 c3" id="h.uijw6ccoyj7v">
            <span className="c0">Trademarks</span>
          </h4>
          <p className="c5 c3">
            <span className="c24">CREWBELLA</span>
            <span className="c1">
              &rsquo;s Trademarks, the CREWBELLA&rsquo;s and CREWBELLA logo, and
              any other product or service name or slogan contained in the Site
              are trademarks of CREWBELLA and its suppliers or licensors, and
              may not be copied, imitated or used, in whole or in part, without
              the prior written permission of CREWBELLA or the applicable
              trademark holder. You may not use meta-tags or any other
              &quot;hidden text&quot; utilizing content or any other name,
              trademark or product or service name of CREWBELLA without our
              prior written permission. In addition, the look and feel of the
              Site, including all page headers, Site name and logo, custom
              graphics, button icons and scripts, is the service mark, trademark
              and/or trade dress of CREWBELLA and may not be copied, imitated or
              used, in whole or in part, without our prior written permission.
              All other trademarks, registered trademarks, product names and
              company names or logos mentioned in the Site are the property of
              their respective owners. Reference to any products, services,
              processes or other information, by trade name, trademark,
              manufacturer, supplier or otherwise does not constitute or imply
              endorsement, sponsorship or recommendation thereof by us.
            </span>
          </p>
          <h4 className="c8 c3" id="h.hohtb2flfdrw">
            <span className="c0">Indemnity</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              You agree to defend, indemnify and hold harmless CREWBELLA, its
              subsidiaries, affiliates, licensors, employees, agents, third
              party information providers and independent contractors against
              any claims, damages, costs, liabilities and expenses (including,
              but not limited to, reasonable attorneys&#39; fees) arising out of
              or related to any User Content that you post, store or otherwise
              transmit on or through the Site, your conduct, your use or
              inability to use the Site, your breach or alleged breach of the
              Site Terms or of any representation or warranty contained herein,
              your unauthorized use of the Content, or your violation of any
              rights of another. Further you shall indemnify CREWBELLA, its
              subsidiaries, its affiliates and licensors against any loss,
              expense, cost or damage incurred by any or all of them as a result
              of your breach of these Terms or your unauthorized use of the
              Content or the Site.
            </span>
          </p>
          <h4 className="c8 c3" id="h.ku52bcsmpl3c">
            <span className="c0">Termination</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              Notwithstanding any of these Site Terms, CREWBELLA reserves the
              right, without notice and in its sole discretion, to terminate
              your account and/or to block your use of the Site.
            </span>
          </p>
          <h4 className="c8 c3" id="h.2xek303toflb">
            <span className="c0">Changes to Site Terms</span>
          </h4>
          <p className="c5 c3">
            <span className="c24">CREWBELLA</span>
            <span className="c1">
              &nbsp;reserves the right to change any of the terms and conditions
              contained in the Site Terms or any policy or guideline of the
              Site, at any time and in its sole discretion. Any changes will be
              effective immediately upon posting on the Site. Your continued use
              of the Site following the posting of changes will constitute your
              acceptance of such changes. We encourage you to review the Site
              Terms whenever you visit the website.
            </span>
          </p>
          <h4 className="c8 c3" id="h.7k6au1kuzudz">
            <span className="c0">Chargeback</span>
          </h4>
          <p className="c3 c20">
            <span className="c1">
              You hereby expressly agree that once you or any other person have
              started the process of downloading content from the site by
              expressly, without reservation, agreeing to the terms and
              conditions of the site and license agreement, by clicking and
              accepting them and have further submitted your credit card/ any
              other card details to the site/internet banking service provider
              for payment and your account gets debited no chargeback
              claim/dispute with regard to the failure of delivery of Product/
              Content or the use/misuse of such credit card or any other card,
              filed by you/on your behalf with the Credit Card Provider/Internet
              banking service provider or any Bank/Financial Institution shall
              be binding on CREWBELLA or CREWBELLA&rsquo;s Merchant Bank. You
              accept and take full responsibility towards the
              ownership/possession and use of your credit card or any other
              card. You expressly waive and consciously give up any/all rights
              you may have in law or in any agreement between you and the credit
              card or any other card provider/internet service provider or under
              network rules and regulations to raise any dispute to claim any
              charge back or any process fee from any Bank/Financial
              Institution/ Internet banking service provider affecting CREWBELLA
              in any manner pecuniary or otherwise on any grounds whatsoever
              including non delivery of Product/Content or fraudulent or
              improper transactions by the unauthorized/improper use of your
              credit/debit card or any other card. This understanding that you
              will have no right to claim any chargeback is a material condition
              of this agreement and once you hereby expressly agree to it you
              shall be stopped from challenging/disputing it in any
              adjudication/proceeding before any Bank/Financial institution/
              Internet banking service provider or Court or otherwise. Without
              prejudice to CREWBELLA&rsquo;s right to defend its interest(s),
              CREWBELLA shall not be made a party to any chargeback proceedings
              before any Bank/Financial institution/ Internet banking service
              provider or court and in no case be required to prove the
              transaction effected by the use of your credit/debit or any other
              card was legitimate and genuine, or that CREWBELLA has actually
              provided you the downloaded content. Please refer to our Refund
              &amp; Cancellation policy for more details.
            </span>
          </p>
          <h4 className="c8 c3" id="h.3fdgwpasvlrk">
            <span className="c0">Severance &amp; Waiver</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              No action of CREWBELLA, other than an express written waiver or
              amendment, may be construed as a waiver or amendment of any of
              these Terms. If any clause in these Terms is found to be
              unenforceable, wherever possible, it will not affect any other
              clause and each will remain in full force and effect. We reserve
              the right to change these Terms, the Content displayed on the
              Site, any license contained on the Site and any other information
              or license terms without prior notice. These Terms set out the
              entire agreement between CREWBELLA and you relating to your use of
              this Site. Any rights not expressly granted herein are reserved.
            </span>
          </p>
          <h4 className="c8 c3" id="h.3moram92o9x7">
            <span className="c0">Jurisdiction</span>
          </h4>
          <p className="c5 c3">
            <span className="c1">
              All license agreements, use or any issues arising out of any
              activity regarding the use of this website will be governed by the
              laws of India and subject to the exclusive jurisdiction of courts
              in Ajmer.
            </span>
          </p>
          <h4 className="c8 c3" id="h.h84kfxgo1hm0">
            <span className="c0">
              Terms of Conduct while making Contract or QuickBook
            </span>
          </h4>
          <ol className="c13 lst-kix_frftrfn6jno0-0 start" start="1">
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                The Contract and Quickbook are &nbsp;Legal Entity which can be
                used as a proof for your association with the Client/Crew and
                the Company [CREWBELLA]
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                It is an integral service provided to the &ldquo;Registered
                users&rdquo; of the Website, to Provide them Security and Trust
                over their Job.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                For Contract, The service comes with a constant commission fee
                of
                <br />
                20% on a contract of 30,000 INR (Thirty Thousand) INR or less
                <br />
                18% on a Contract more than 30,000 (Thirty Thousand) INR and
                less than 3,00,000 (Three lakhs) INR
                <br />
                15% on a contract of 3,00,000 (Three lakhs) INR or more
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                For Quickbook, the commission fee is constant at 15% from the
                final amount paid to the crew irrespective of any number.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                The Commission fee is applicable to both Client and Crew; as
                Security, Operations and Customer Services are being provided to
                both the signed parties without any Biases.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                Every Contract &amp; Quickbook will be supervised by the company
                appointed Proctor .
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                The company is not liable for any loss or damage that happened
                during the contract period, if such a situation arises ,the
                signed parties should come to mutual terms by themselves.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                It is advised to both parties to state each and every term
                carefully in the contract and mention all possible scenarios
                that can take place during the tenure of work.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c6">
                Any party, can choose the option &ldquo;Report Contract&rdquo;
                or &ldquo;Report Quickbook&rdquo; in case of any misfortunate
                events like:
                <br />
                1) The fellow party is not following the terms of contract or
                Violating them.
                <br />
                2) The fellow party has given false information about itself or
                it&rsquo;s organization
                <br />
                3) The fellow party is condescending on modifying the payment
                <br />
                4) The fellow party has failed to fulfill the requirements
                mentioned in the contract signed by them.
              </span>
            </li>
          </ol>
          <p className="c7">
            <span className="c6">
              5) The fellow party is involved in the activities of
              harassment/assault/bullying with you
              <br />
              6) The fellow party has added extra requirements which are not
              mentioned and signed in the contract
            </span>
          </p>
          <p className="c7">
            <span className="c6 c16">
              7) The fellow party is missing/has missed the deadline(s).
            </span>
          </p>
          <ol className="c13 lst-kix_frftrfn6jno0-0" start="10">
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                In case, any of the party chooses to &quot;Report
                Contract&quot;, the company will assign one of its employees to
                supervise the situation.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                No changes are allowed once the contract is signed by both the
                parties.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                The Contract will be considered &ldquo;Completed&rdquo; only in
                the following Condition(s):
                <br />
                1)The Client has made the final payment to the company and The
                Crew has &nbsp; completed and transferred it&rsquo; s work to
                the client
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                The contract will be considered &quot;Terminated&quot; only
                under following Condition(s) :
              </span>
            </li>
          </ol>
          <p className="c7">
            <span className="c6">
              1) Either of the parties ``Reports&#39; &#39; the contract and has
              provided proof to the{" "}
            </span>
            <span className="c19">Crewbella&rsquo;s</span>
            <span className="c16 c6">
              &nbsp;contract supervisor who examines the case and approves to
              &ldquo;Terminate&rdquo; the contract.
              <br />
              2) If any or both the parties do not respond to the &ldquo;
              Complete Contract&rdquo; function within 30 Days after the
              Deadline date of the contract.
            </span>
          </p>
          <ol className="c13 lst-kix_frftrfn6jno0-0" start="14">
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                CREWBELLA does not take any responsibility for any communication
                or deal between the parties outside the contract feature.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                It is compulsory for both the parties to upload their Government
                Id&rsquo;s and contact information while making the contract.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                The contract registration takes 3-4 Business days (Excluding
                weekends and Government Holidays) for approval from the
                Administration Department.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                All the changes suggested in the contract should be done within
                48 hours. Exceeding the deadline will terminate the process.{" "}
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                The Copyright/ownership /Credit of the content /service/ work
                will be equally shared by both the parties unless stated
                otherwise by any one of the two parties.
              </span>
            </li>
            <li className="c7 c12 li-bullet-0">
              <span className="c16 c6">
                The Copyright/ownership/Credit of the content/Service/work
                provided by the Crew is only given to the client after full
                payment is made for that Content/Service/Work.
                <br />
                If a client claims credit/ownership/Copyright on the
                content/service/work without making the complete payment to the
                Crew, The Crew has the right to take strict legal action against
                the client.
              </span>
            </li>
          </ol>
          <p className="c21">
            <span className="c16 c6"></span>
          </p>
          <h4 className="c22" id="h.ug058go2cg1f">
            <span className="c23">Terms specific for Quickbook</span>
          </h4>
          <ul className="c13 lst-kix_qjenrg8ghelh-0 start">
            <li className="c9 li-bullet-0">
              <span className="c4">
                The Quickbook feature allows Clients to hire crew within 3-72
                hours of job posting
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span className="c4">
                The Client can book a crew for a maximum 14 days.
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span className="c4">
                The client can convert the Quickbook into Contract if he/she
                wishes to work with the crew for more than 14 days.
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span className="c4">
                The quickbook feature works on 100% advance payment operation.
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span className="c4">
                CREWBELLA provides customer services related to Job conduct and
                bookings only. It does not take responsibility for other
                services such as logistics, Transportation, food, accommodation
                etc between the parties. The Client &amp; the crew will have to
                figure it out among themselves.
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span className="c4">
                The crew is entitled to provide his/her full day to the client.
                Other bookings and jobs are not allowed during the booking
                period.
              </span>
            </li>
            <li className="c9 li-bullet-0">
              <span>
                Realtime &amp; Daily feedback are compulsory for both Crew &amp;
                Client as it helps maintain conduct between the two parties.
              </span>
            </li>
          </ul>
        </div>
      </Flex>
    </Flex>
  );
};

export default UserAggrement;
