import react,{} from 'react'
import { useSearchParams } from 'react-router-dom'

const DynamicLanding = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    

    return (
        <div>
            <h1>Dynamic Landing</h1>
            <h2>{searchParams.get('id')}</h2>
            </div>
    );
}


export default DynamicLanding