import React from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Button,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";


const FAQPage = () => {

    const faqs = [
        {
            question: "What is Crewbella?",
            answer: "An ecosystem  of media outlets that let's you hire people from the industry according to your requirements.",
        },
        {
            question: "Who can register?",
            answer: "Any one who's looking to hire someone from the industry or is willing to be hired by someone",
        },
        {
            question: "Why Crewbella?",
            answer: "Because we work to provide you the opportunities that are usually difficult to access. So! No hassle and no struggle to apply to various brands.",
        },
        {
            question: "What does media mean here?",
            answer: "By saying media, we mean that all kinds of media. Be it Journalism, Films, music, web. Everything! Crewbella doesn't let anyone go unnoticed.",
        },
        {
            question: "Is it safe?",
            answer: "Yes! We will make sure that your tenure with the person you are working is bound by contract. This is a legally safe platform.",
        },
        {
            question: "What is new?",
            answer: "Our exclusivity towards the media industry. We bring specific services only for media personnel and even the ones who want to be part of this ecosystem.",
        },
        {
            question: "Why I don't need a CV on Crewbella?",
            answer: "Because your profile acts like your portfolio. It is a testimony of your entire work. One look and the person knows everything you have worked on.",
        },{
            question: "What does it do?",
            answer: "reduced struggle, more opportunities, all kinds of services in the industry",
        },{
            question: "What are the advantages?",
            answer: "Gives Worldwide coverage\nProvides you opportunities bound by a legal contract.\nAccountability and security.",
        },{
            question: "How to find someone?",
            answer: "Enter your city, type the domain of interest and the profiles would be visible.\nOr\nPost your requirements and you will be approached.",
        },
        
    ];


    return (
        <Flex flexDirection="column" w={"430px"}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            fontFamily="Roboto"
            marginLeft="10px"
            mb={"10px"}
          >
            FAQ
          </Text>
          <Accordion allowToggle>
            {faqs.map((faq, index) => (
                <AccordionItem key={index}>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            {faq.question}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {faq.answer}
                    </AccordionPanel>
                </AccordionItem>
            ))}
          </Accordion>
        </Flex>
    );
    };

export default FAQPage;