const RequirementsList = [
    "Animator",
    "Artist Management",
    "Asst. Director",
    "Brand Manager",
    "Camera & Equipment Rental Service",
    "Casting Director",
    "Cinematographer",
    "Content Writer",
    "Copy Writer",
    "Costume Designer",
    "Creative Head",
    "Digital Marketing",
    "Director",
    "Director of Photography",
    "Dl Colorist",
    "Distributer",
    "Editor",
    "Event Management",
    "Financer",
    "Foley Artist",
    "Gaffer",
    "Graphic Artist",
    "Graphic Designer",
    "Hair & Makeup Artist",
    "Journalist",
    "Junior Artist Management",
    "Line Producer",
    "Lyricist",
    "Music Composer",
    "News Anchor",
    "Photographer",
    "Photography Studio",
    "Production Controller",
    "Producer",
    "Production House",
    "Public Relations",
    "Radio Jockey",
    "Reporter",
    "Screenwriter",
    "Set Design",
    "Sound Designer",
    "Sound Effect Artist",
    "Storyboard Artist",
    "TV Correspondent",
    "Video Jockey",
    "Video Editor",
    "Video Effect Artist",
    "Voice Over Artist",
    "Web Designer",
    "Web Developer",
  ];

export default RequirementsList;