import { createContext,useState } from "react";


export const Context = createContext();

export default function ContextProvider({ children }) {

    const [loggedIn, setLoggedIn] = useState(false);
    const [userIdDB, setUserIdDB] = useState(null);
    const [applied, setApplied] = useState([]);
    const [created, setCreated] = useState([]);
    const [isModalAppliedOpen, setisModalAppliedOpen] = useState(false);
    const [modalAppliedText, setModalAppliedText] = useState("Applied succesfully 🎉");
    const [modalAppliedLink, setModalAppliedLink] = useState("");
    

  return <Context.Provider value={{
    loginStatus: [loggedIn, setLoggedIn],
    appliedData : [applied, setApplied],
    createdData : [created, setCreated],
    appliedModalStatus : [isModalAppliedOpen, setisModalAppliedOpen],
    appliedModelText : [modalAppliedText, setModalAppliedText],
    appliedModelLink : [modalAppliedLink, setModalAppliedLink],
    userInfo : [userIdDB, setUserIdDB]
  }}>{children}</Context.Provider>;
}