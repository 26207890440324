/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Button,
  Divider,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState,useContext } from "react";
import { CBColors } from "../../config";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { imagesApi } from "../../config";
import { Context } from "../../provider/context";
const PublicCard = ({ data }) => {
  //read more state
  const [readMore, setReadMore] = useState(false);
  const { loginStatus, appliedData, createdData,appliedModalStatus,appliedModelText,appliedModelLink} = useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [isModalAppliedOpen, setisModalAppliedOpen] = appliedModalStatus;
  const [modalAppliedText, setModalAppliedText] = appliedModelText;
  const [modalAppliedLink, setModalAppliedLink] = appliedModelLink;


  //read more function
  const handleReadMore = () => {
    setReadMore(!readMore);
  };


  const chat = async() => {
    setModalAppliedText("This feature is available only for the users with app.\n Please download the app to chat with the creator.");
    setModalAppliedLink("https://play.google.com/store/apps/details?id=com.crewbella.app");
    setisModalAppliedOpen(true);
  }
  const apply = async() => {}

  const lead = data;

  return (
    <Card
      color={CBColors.imEverywhere}
      w={"368px"}
      h={"310px"}
      borderRadius={"16px"}
      css={
        "box-shadow: 1px -2px 15px rgba(221, 221, 221, 0.25), 0px 4px 15px -7px rgba(221, 221, 221, 0.25);"
      }
    >
      <CardHeader p={"16px"}>
        <Flex spacing="4">
          <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
            <Avatar
              name={lead.creatorName}
              src={`${imagesApi}${lead.creatorAvatar}`}
              size={"md"}
            />
            <Box>
              <Heading color={"black"} size="sm" fontSize={"16px"} w="220px">
                {lead.creatorName}
              </Heading>
              <Text color={"black"}>{"@" + lead.creatorTag}</Text>
            </Box>
          </Flex>
          <IconButton
            variant="ghost"
            colorScheme="gray"
            aria-label="See menu"
            color="black"
            visibility={"hidden"}
            icon={<BsThreeDotsVertical />}
          />
        </Flex>
      </CardHeader>
      <CardBody px={"16px"} pt={"0px"} pb={"8px"}>
        {readMore ? (
          <Box
            w={"328px"}
            h={"112px"}
            mb={"16px"}
            overflowY="auto"
            userSelect="none"
            cursor="pointer"
            drag="y"
            color={CBColors.textBox}
          >
            <Text color={"black"} fontSize={"12px"} fontWeight={"400"}>
              {lead.description}
            </Text>
          </Box>
        ) : (
          <motion.div
            animate={{ rotateY: readMore ? 180 : 0 }}
            transition={{ duration: 0.5 }}
            style={{ perspective: "1000px" }}
          >
            <Flex justifyContent="space-between" align="center" mb={"16px"}>
              <Flex flexDirection="column" alignItems="start">
                <Text color={"black"} fontSize={"12px"} fontWeight={"300"}>
                  Looking For
                </Text>
                <Text color={"black"} fontSize={"16px"} fontWeight={"500"} >
                  {lead.lookingFor}
                </Text>
              </Flex>
              <Flex flexDirection="column" alignItems="end">
                <Text color={"black"} fontSize={"12px"} fontWeight={"300"}>
                  Location
                </Text>
                <Text color={"black"} fontSize={"16px"} fontWeight={"500"}>
                  {lead.location}
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" align="center" mb={"8px"}>
              <Flex flexDirection="column" alignItems="start">
                <Text color={"black"} fontSize={"12px"} fontWeight={"300"}>
                  Project Date
                </Text>
                <Text color={"black"} fontSize={"16px"} fontWeight={"500"}>
                  {lead.duration.startDate}
                </Text>
              </Flex>
              <Flex flexDirection="column" alignItems="end">
                <Text color={"black"} fontSize={"12px"} fontWeight={"300"}>
                  Description
                </Text>
                <Text
                  color={"black"}
                  fontSize={"20px"}
                  fontWeight={"500"}
                  maxW={"150px"}
                  isTruncated
                >
                  {lead.description}
                </Text>
              </Flex>
            </Flex>
          </motion.div>
        )}
        <Flex justifyContent="space-between" align="center"  mb={"10px"}>
          <Text
            color={"black"}
            fontSize={"16px"}
            fontWeight={"500"}
            maxW={"150px"}
            isTruncated
          >
            Budget
          </Text>
          <Text
            color={"black"}
            fontSize={"12px"}
            fontWeight={"300"}
            maxW={"150px"}
            isTruncated
          >
            {'₹'+(lead.budget !=null?lead.budget: "0")}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" align="center" mb={"16px"}>
          <Flex align="center">
            <Text
              color={"black"}
              fontSize={"12px"}
              fontWeight={"500"}
              mr={"4px"}
            >
              {lead.usersApplied.length}
            </Text>
            <Text color={"black"} fontSize={"12px"} fontWeight={"300"}>
              applicants
            </Text>
          </Flex>
          <Button
            alignSelf={"flex-end"}
            variant="link"
            color={"black"}
            fontSize={"18px"}
            fontWeight={"500"}
            onClick={handleReadMore}
            cursor="pointer"
          >
            {readMore ? "Read Less" : "Read More"}
          </Button>
        </Flex>
        <Flex
          justify="space-between"
          flexWrap="wrap"
          sx={{
            "& > button": {
              maxW: "149px",
              borderRadius: "13px",
              h: "32px",
            },
          }}
        >
          <Button flex="1" bg={CBColors.go} onClick={apply}>
            Apply
          </Button>
          <Button flex="1" bg={CBColors.wait} onClick={chat}>
            Chat
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PublicCard;
