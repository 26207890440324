// export const BASE_URL = process.env.BASE_URL || process.env.NODE_ENV === 'production' ? 'https://api.crewbella.com/api/v1' : 'http://localhost:1269/api/v1';
export const BASE_URL = process.env.BASE_URL ||'https://api.crewbella.com/api/v1';

export const CBColors = {
  bg: "#FFFFFF",
  baseRed: "#C50707",
  textBox: "#FFF9F9",
  go: "#6EBD84",
  wait: "#EDA313",
  slowDown: "#DE5753",
  read: "#000000",
  imEverywhere: "#FFFFFF",
  faded: "#C4C4C4",
  footer:"#222c32"
};


//
export const getALlLeadsApi = BASE_URL + '/leads';
export const getALeadsApi = BASE_URL + '/lead';
export const createLeadsApi = BASE_URL + '/lead/create';
export const applyLeadApi = BASE_URL + '/lead/apply';
export const emailPhoneCheckApi = BASE_URL + '/user/check';
export const getAllUsersApi = BASE_URL + '/users';
export const getUserApi = BASE_URL + '/user';
export const getCurrentUserApi =BASE_URL + '/user/self';
export const updateHireStatusUserApi =BASE_URL + '/user/hire';
export const updateUserApi =BASE_URL + '/user/update';
export const imagesApi =BASE_URL + '/files/get/?file=';
export const auth =BASE_URL + '/signup/email';
export const authGoogle =BASE_URL + '/signup/google';
export const authSignin =BASE_URL + '/signin';