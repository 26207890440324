import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./views/homepage/homepage";
import Events from "./views/events/events";
import PrivacyPolicy from "./views/privacy-policy/privacy-policy";
import UserAggrement from "./views/user-aggremnt/user-aggrement";
import ErrorPage from "./views/404/404";
import { useEffect, useState, useContext } from "react";
import SplashScreen from "./components/splash/splashScreen";
import DynamicLanding from "./views/dynamic_landing/landing";
import { Context } from "./provider/context";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const { loginStatus, appliedData, createdData,userInfo } = useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [userIdDB, setUserIdDB]= userInfo;
  useEffect(() => {
    localStorage.getItem("token") ? setLoggedIn(true) : setLoggedIn(false);
    localStorage.getItem("applied") ? setApplied(JSON.parse(localStorage.getItem("applied"))) : setApplied([]);
    localStorage.getItem("created") ? setCreated(JSON.parse(localStorage.getItem("created"))) : setCreated([]);
    localStorage.getItem("userId") ? setUserIdDB(localStorage.getItem("userIdDB")) : setUserIdDB(null);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Router>
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route path="/events" element={<Events />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<UserAggrement />} />
              <Route path="/lead" element={<DynamicLanding />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
