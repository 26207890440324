import {
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Button,
  VStack,
  useToast
} from "@chakra-ui/react";

import React, { useState, useEffect,useContext } from "react";
import logoImage from "../../assets/images/logo-text.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { CBColors } from "../../config";
import SignInSignUpModal from "../widgets/auth_popup";
import homeIcon from "../../assets/images/Home_icon.png";
import eventsIcon from "../../assets/images/events_icon.png";
import { useNavigate } from "react-router";
import LeadPostingModal from "../widgets/lead_post_popup";
import InstantContractModal from "../widgets/instant_contract_popup";
import AppliedModal from "../widgets/applied_popup";
import BestWorks from "../widgets/best_works";
import { BiLogOut } from "react-icons/bi";
import { Context } from "../../provider/context";

const Header = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded,] = useState(false);
  const { loginStatus, appliedData, createdData,appliedModalStatus,appliedModelText,appliedModelLink } = useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [isModalAppliedOpen, setisModalAppliedOpen] = appliedModalStatus;
  const [modalAppliedText, setModalAppliedText] = appliedModelText;
  const [modalAppliedLink, setModalAppliedLink] = appliedModelLink;

  useEffect(() => {
    const button = document.getElementById("hamburger-button");
    if (isExpanded) {
      button.classList.add("expanded");
    } else {
      button.classList.remove("expanded");
    }
  }, [isExpanded]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const toast = useToast()

  const showComingSoonToast = () => {
    toast({
      title: "Coming Soon",
      status: "info",
      duration: 5000,
      isClosable: true,
    })
  };

  const [isModalAuthOpen, setIsAuthModalOpen] = useState(false);
  const [isModaLeadPostinglOpen, setisModaLeadPostinglOpen] = useState(false);
  const [isModalInstantContractOpen, setisModalInstantContractOpen] = useState(false);
 

  const handleAuthModalOpen = (value) => {
    setIsAuthModalOpen(value);
  };

  const handleLeadModalOpen = (value) => {
    setisModaLeadPostinglOpen(value);
  };

  const handleInstantModalOpen = (value) => {
    setisModalInstantContractOpen(value);
  };
  const handleAppliedModalOpen = (value) => {
    setisModalAppliedOpen(value);
  };

  return (
    <Box>
      <Flex
        w={"36%"}
        maxW={"510px"}
        bg={CBColors.bg}
        pt="10px"
        align="center"
        pos={"relative"}
      >
        <Box
          // w={"460px"}
          w={"calc(100% - 65px)"}
          bg="red"
          borderRadius="20px"
          marginRight="10px"
          h="72px"
          display="flex"
          align-items="center"
        >
          <Flex
            justifyContent="space-between"
            align="center"
            px={"17px"}
            w={"100%"}
          >
            <Image
              src={logoImage}
              alt="Logo"
              w="151.91px"
              h="49.5px"
              // marginLeft="17px"
              // marginRight="210px"
            />
            { !loggedIn ? (
            <Button
              bg="transparent"
              color="white"
              marginTop="25px"
              marginBottom="25px"
              _hover={{ bg: "transparent", color: "red" }}
              onClick={handleAuthModalOpen}
            >
              Sign In
            </Button>
            ) : (
              <Button
              bg="transparent"
              color="white"
              marginTop="25px"
              marginBottom="25px"
              _hover={{ bg: "transparent", color: "red" }}
              onClick={()=>{
                localStorage.removeItem("token");
                localStorage.removeItem("created");
                localStorage.removeItem("applied");
                setLoggedIn(false);
                setCreated([]);
                setApplied([]);
              }}
            >
              <BiLogOut/>
            </Button>
            )}
            { isModalAuthOpen && (
              <SignInSignUpModal
                isOpen={isModalAuthOpen}
                onClose={() => handleAuthModalOpen(false)}
                text="Applied succesfully 🎉"
                // link="/"
              />
            )}
            {isModaLeadPostinglOpen && (
              <LeadPostingModal
                isOpen={isModaLeadPostinglOpen}
                onClose={() => handleLeadModalOpen(false)}
              />
            )}
            {isModalInstantContractOpen && (
              <InstantContractModal
                isOpen={isModalInstantContractOpen}
                onClose={() => handleInstantModalOpen(false)}
              />
            )}
             {isModalAppliedOpen && (
              <AppliedModal
                isOpen={isModalAppliedOpen}
                onClose={() => handleAppliedModalOpen(false)}
                text={modalAppliedText}
                link={modalAppliedLink}
              />
            )}
            
          </Flex>
        </Box>
        <Box
          id="hamburger-button"
          w={isExpanded ? "500px" : "63px"}
          position="absolute"
          left={"calc(100% - 53px)"}
          h="72px"
          bg="red"
          // pr={isExpanded? "300px" : "0px"}

          borderRadius="16px"
          display="flex"
          alignItems={"center"}
          justifyContent={isExpanded ? "start" : "center"}
          // transition="all 0.2s ease-in-out"
          onClick={isExpanded? ()=>{}: handleClick}
          pl={isExpanded && "16px"}
          _hover={{ cursor: "pointer" }}
        >
          {isExpanded ? (
            <Flex align={"center"} justifyContent="space-evenly" w={"100%"}>
              <ImCross size="24px" color="white" onClick={handleClick}/>
              <Image src={homeIcon} alt="Home" style={{ filter: "invert(100%)" }} h={"28px"} w={"28px"} onClick={()=>{
                if(window.location.pathname !== "/"){
                  navigate("/");
                }else{
                  window.location.reload();
                }
              }}/>
              <Image src={eventsIcon} alt="Event" style={{ filter: "invert(100%)" }} h={"24px"} w={"24px"} onClick={()=>{
                showComingSoonToast();
                //  if(window.location.pathname !== "/events"){
                //   navigate("/events");
                // }else{
                //   window.location.reload();
                // }
              }}/>
              <Button color={"black"} bg={CBColors.imEverywhere} borderRadius={"16px"} w={"153px"} onClick={handleLeadModalOpen}>Post requirement</Button>
              <Button color={"black"} bg={CBColors.imEverywhere} borderRadius={"16px"} w={"153px"} onClick={showComingSoonToast}>Instant contract</Button>
            </Flex>
          ) : (
            <GiHamburgerMenu size="24px" color="white"  />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
