import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <Flex flexDirection={"column"}>
      <Helmet>
        <title>Privacy Policy | Crewbella</title>
      </Helmet>

      <Text px={"40px"} fontSize={"20px"} fontWeight={"bold"}>
        Privacy Policy
      </Text>
      <Flex px={"50px"}>
        <div>
          <p className="c21">
            <span className="c4">
              Crewbella’s mission is to connect the world’s media professionals
              to allow them to be more productive and successful. Central to
              this mission is our commitment to be transparent about the data we
              collect about you, how it is used and with whom it is shared.
            </span>
          </p>
          <p className="c13">
            <span className="c4">
              This Privacy Policy applies when you use our Services (described
              below). We offer our users choices about the data we collect, use
              and share as described in this Privacy Policy,&nbsp;
            </span>
            <span className="c35 c36">Settings</span>
            <span className="c4">&nbsp;and our Help Center. </span>
          </p>
          <p className="c28 c11">
            <span className="c4"></span>
          </p>
          <p className="c28">
            <span className="c4">Introduction</span>
          </p>
          <p className="c13">
            <span className="c4">Our registered users (“</span>
            <span className="c24 c29">Members</span>
            <span className="c4">
              ”) share their professional identities, engage with their network,
              exchange knowledge and professional insights, post and view
              relevant content, learn and develop skills, and find business and
              career opportunities. Content and data on some of our Services is
              viewable to non-members (“
            </span>
            <span className="c29 c24">Visitors</span>
            <span className="c4">”).</span>
          </p>
          <p className="c10">
            <span className="c4"></span>
          </p>
          <p className="c21">
            <span className="c3 c9">Crewbella </span>
            <span className="c15 c9">(properitership) </span>
            <span className="c3 c9">&nbsp;and/or its affiliates (</span>
            <span className="c7 c24 c9">"Crewbella,"</span>
            <span className="c3 c9">&nbsp;the&nbsp;</span>
            <span className="c7 c24 c9">"Company," "we," "us,"</span>
            <span className="c3 c9">&nbsp;and&nbsp;</span>
            <span className="c7 c9 c24">"our,"</span>
            <span className="c3 c9">
              ) respect your privacy and is committed to protecting it through
              its compliance with its privacy policies. This policy describes:
            </span>
          </p>
          <ul className="c19 lst-kix_list_2-0 start">
            <li className="c9 c23">
              <span className="c35">
                the types of information that Crewbella may collect from you
                when you access or use its websites, and online services
                (collectively, referred as&nbsp;
              </span>
              <span className="c37">"Services"</span>
              <span className="c4">); and</span>
            </li>
            <li className="c12 c9">
              <span className="c4">
                its practices for collecting, using, maintaining, protecting and
                disclosing that information.
              </span>
            </li>
          </ul>
          <p className="c10">
            <span className="c4">
              <br />
            </span>
          </p>
          <p className="c6">
            <span className="c4">
              This policy applies only to the information Crewbella collects
              through its Services, in email, text,events,competitions and other
              electronic communications sent through or in connection with its
              Services.
            </span>
          </p>
          <p className="c6">
            <span className="c4">
              This policy DOES NOT apply to information that you provide to, or
              that is collected by, any third-party, such as the people you
              interact with or talk to on a personal basis through Crewbella’s
              Services and social networks that you use in connection with its
              Services. Crewbella encourages you to consult directly with such
              third-parties about their privacy practices.
            </span>
          </p>
          <p className="c6">
            <span className="c4">
              Please read this policy carefully to understand Crewbella's
              policies and practices regarding your information and how
              Crewbella will treat it. By accessing or using its Services and/or
              registering for an account with Crewbella, you agree to this
              privacy policy and you are consenting to Crewbella's collection,
              use, disclosure, retention, and protection of your personal
              information as described here. If you do not provide the
              information Crewbella requires, Crewbella may not be able to
              provide all of its Services to you.
            </span>
          </p>
          <p className="c6">
            <span className="c35">
              If you reside in any other part of the world,&nbsp;
            </span>
            <span className="c37">Crewbella (properitership)</span>
            <span className="c4">
              , located at (A-541,Madhuban Marg, Chandravardai Nagar, Ajmer,
              Rajasthan, India) India will be the controller of your personal
              data provided to, or collected by or for, or processed in
              connection with our Services.
            </span>
          </p>
          <p className="c6">
            <span className="c4">
              Your data controller is responsible for the collection, use,
              disclosure, retention, and protection of your personal information
              in accordance with its privacy standards as well as any applicable
              national laws. Your data controller may transfer data to other
              members of Crewbella as described in this Privacy Policy.
              Crewbella may process and retain your personal information on its
              servers in India where its data centers are located, and/or on the
              servers of its third parties (in or outside India), having
              contractual relationships with Crewbella.
            </span>
          </p>
          <p className="c6">
            <span className="c4">
              This policy may change from time to time, your continued use of
              Crewbella's Services after it makes any change is deemed to be
              acceptance of those changes, so please check the policy
              periodically for updates.
            </span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;The information we collect and how we use it
            </span>
          </p>
          <p className="c6">
            <span className="c15">Crewbella.com&nbsp;</span>
            <span className="c7">("Crewbella,"</span>
            <span className="c15">&nbsp;the&nbsp;</span>
            <span className="c7">"Company," "we," "us,"</span>
            <span className="c15">&nbsp;and&nbsp;</span>
            <span className="c7">"our”)</span>
            <span className="c3">
              &nbsp;collects several types of information from and about users
              of our Services, including:
            </span>
          </p>
          <ul className="c19 lst-kix_list_3-0 start">
            <li className="c23 c9">
              <span className="c3">
                Your Personal Information("PI") - Personal Information is the
                information that can be associated with a specific person and
                could be used to identify that specific person whether from that
                data, or from the data and other information that we have, or is
                likely to have access to. We do not consider personal
                information to include information that has been made anonymous
                or aggregated so that it can no longer be used to identify a
                specific person, whether in combination with other information
                or otherwise.
              </span>
            </li>
            <li className="c9 c12">
              <span className="c3">
                Information about your internet connection, the equipment you
                use to access our Services and your usage details.
              </span>
            </li>
          </ul>
          <p className="c13 c9">
            <span className="c3">
              <br />
              We collect this information:
            </span>
          </p>
          <ul className="c19 lst-kix_list_4-0 start">
            <li className="c23 c9">
              <span className="c3">
                directly from you when you provide it to us; and/or
              </span>
            </li>
            <li className="c12 c9">
              <span className="c3">
                automatically as you navigate through our Services (information
                collected automatically may include usage details, IP addresses
                and information collected through cookies, web beacons and other
                tracking technologies).
              </span>
            </li>
          </ul>
          <p className="c21 c11 c26">
            <span className="c18"></span>
          </p>
          <p className="c6">
            <span className="c3">
              The information we collect on or through our Services may include:
            </span>
          </p>
          <ul className="c19 lst-kix_list_5-0 start">
            <li className="c23 c9">
              <span className="c7">Your account information:</span>
              <span className="c3">
                &nbsp;Your full name, email address, postal code, password and
                other information you may provide with your account, such as
                your gender, mobile phone number and website. Your profile
                picture that will be publicly displayed as part of your account
                profile. You may optionally provide us with this information
                through third-party sign-in services such as Facebook and Google
                Plus. In such cases, we fetch and store whatever information is
                made available to us by you through these sign-in services.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your preferences:</span>
              <span className="c3">
                &nbsp;Your preferences and settings such as time zone and
                language.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your content:</span>
              <span className="c3">
                &nbsp;Information you provide through our Services, including
                your reviews, photographs, comments, lists, the users you
                connect with, current and prior clients/crew details, companies
                and their employees, contact information of people you add to,
                or notify of, your whole portfolio through our Services, names,
                and other information you provide on our Services, and other
                information in your account profile.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your searches and other activities:</span>
              <span className="c3">
                &nbsp;The search terms you have looked up and results you
                selected.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your browsing information:</span>
              <span className="c3">
                &nbsp;How long you used our Services and which features you
                used; the ads you clicked on.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your communications:</span>
              <span className="c3">
                &nbsp;Communications between you and other users or merchants
                through our Services; your participation in a survey, poll,
                sweepstakes, contest or promotion scheme; your request for
                certain features (e.g., newsletters, updates or other products);
                your communication with us about employment opportunities posted
                to the services.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your transactional information:</span>
              <span className="c3">
                &nbsp;If you make reservations or purchases through our
                Services, we may collect and store information about you to
                process your requests and automatically complete forms for
                future transactions, including (but not limited to) your phone
                number, address, email, billing information and credit or
                payment card information. This information may be shared with
                third-parties which assist in processing and fulfilling your
                requests, including PCI compliant payment gateway processors.
                When you submit credit or payment card information, we encrypt
                the information using industry standard technologies, as further
                described below under "Payment Card Information." If you write
                reviews about users with which you conduct transactions through
                our Services, we may publicly display information that you
                transacted with those users..
              </span>
            </li>
            <li className="c12 c9">
              <span className="c7">Your Public Posts:</span>
              <span className="c15">
                &nbsp;You also may provide information (such as ratings,
                reviews, requirements, photos, comments, likes, bookmarks,
                friends, lists, etc.) to be published or displayed (hereinafter,
                "posted") on publicly accessible areas of our Services, or
                transmitted to other users of our Services or third-parties
                (collectively,&nbsp;
              </span>
              <span className="c7">"User Contributions"</span>
              <span className="c3">
                ). Your User Contributions are posted on and transmitted to
                others at your own risk. Although we limit access to certain
                pages, you may set certain privacy settings for such information
                by logging into your account profile. Please be aware that no
                security measures are perfect or impenetrable (see "Security"
                section below). Additionally, we cannot control the actions of
                other users of our Services with whom you may choose to share
                your User Contributions. Therefore, we cannot and do not
                guarantee that your User Contributions will not be viewed by
                unauthorized persons. We may display this information on the
                Services, share it with businesses, and further distribute it to
                a wider audience through third party sites and services. You
                should be careful about revealing any sensitive details about
                yourself in such postings.
              </span>
            </li>
          </ul>
          <p className="c9 c16">
            <span className="c3">
              We use the information you provide to us to enhance the
              functionality and improve the quality of our Services, and to
              personalize your experience while using our Services. We also use
              this information to display relevant advertising, provide support
              to you, communicate with you, and comply with our legal
              obligations.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">&nbsp;Information about Your Friends</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              You have the option to request your friends to join the Services
              by providing their contact information. If you request a friend to
              join and connect with you on the Services, we will only use your
              friend's contact information to process your request.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c9 c13">
            <span className="c8">Information about Your Messages</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              If you exchange messages with others through the Services, we may
              store them in order to process and deliver them, allow you to
              manage them, and investigate possible violations of our Terms of
              Service and wrongdoing in connection with the Services. If you
              send information from the Services to your mobile device via SMS
              text message, we may log your phone number, phone carrier, and the
              date and time that the message was processed. Carriers may charge
              recipients for texts that they receive.
            </span>
          </p>
          <p className="c11 c28">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;Information We Collect Through Automatic Data Collection
              Technologies
            </span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              We may automatically collect certain information about the
              computer or devices (including mobile devices) you use to access
              the Services, and about your use of the Services, even if you use
              the Services without registering or logging in.
            </span>
          </p>
          <ul className="c19 lst-kix_list_6-0 start">
            <li className="c23 c9">
              <span className="c7">Usage information:</span>
              <span className="c3">
                &nbsp;Details of your use of our Services, including traffic
                data, location data, logs and other communication data and the
                resources that you access and use on or through our Services.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Computer and device information:</span>
              <span className="c3">
                &nbsp;Information about your computer, Internet connection and
                mobile device, including your IP address, operating systems,
                platforms, browser type, other browsing information (connection,
                speed, connection type etc.), device type, device's unique
                device identifier, mobile network information and the device's
                telephone number.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Stored information and files:</span>
              <span className="c3">
                &nbsp;Our applications also may access metadata and other
                information associated with other files stored on your mobile
                device. This may include, for example, photographs, audio and
                video clips, personal contacts and address book information.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Location information:</span>
              <span className="c3">
                &nbsp;Our applications collect real-time information about the
                location of your device, as permitted by you.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Last URL visited:</span>
              <span className="c3">
                &nbsp;The URL of the last web page you visited before visiting
                our websites.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Mobile device IDs:</span>
              <span className="c3">
                &nbsp;Unique mobile device identifier (e.g. IDFA or other device
                IDs on Apple devices like the iPhone and iPad), if you're using
                our Services on a mobile device, we may use mobile device IDs
                (the unique identifier assigned to a device by the
                manufacturer), instead of cookies, to recognize you. We may do
                this to store your preferences and track your use of our
                applications. Unlike cookies, mobile device IDs cannot be
                deleted. Advertising companies may use device IDs to track your
                use of our applications, track the number of advertisements
                displayed, measure advertising performance and display
                advertisements that are more relevant to you. Analytics
                companies may use mobile device IDs to track your usage of our
                applications.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your preferences:</span>
              <span className="c3">
                &nbsp;Your preferences and settings such as time zone and
                language.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Your activity on the Services:</span>
              <span className="c3">
                &nbsp;Information about your activity on the Services, such as
                your search queries, comments, domain names, search results
                selected, number of clicks, pages viewed and the order of those
                pages, how long you visited our Services, the date and time you
                used the Services, error logs, and other similar information.
              </span>
            </li>
            <li className="c12 c9">
              <span className="c7">Mobile status:</span>
              <span className="c3">
                &nbsp;For mobile application users, the online or offline status
                of your application.
              </span>
            </li>
          </ul>
          <p className="c10 c9 c26">
            <span className="c8"></span>
          </p>
          <p className="c13 c9 c26">
            <span className="c8">
              Precise Location Information and How to Opt Out
            </span>
          </p>
          <ul className="c19 lst-kix_list_6-0">
            <li className="c13 c17 c9">
              <span className="c3">
                When you use one of our location-enabled services (for example,
                when you access Services from a mobile device), we may collect
                and process information about your mobile device's GPS location
                (including the latitude, longitude or altitude of your mobile
                device) and the time the location information is recorded to
                customize the Services with location-based information and
                features (for example, to inform you about{" "}
              </span>
              <span className="c15">Crewmates/Crew</span>
              <span className="c3">
                &nbsp;in your area or applicable promotions). Some of these
                services require your personal data for the feature to work and
                we may associate location data with your device ID and other
                information we hold about you. We keep this data for no longer
                than is reasonably necessary for providing services to you. If
                you wish to use the particular feature, you will be asked to
                consent to your data being used for this purpose. You can
                withdraw your consent at any time by disabling the GPS or other
                location-tracking functions on your device, provided your device
                allows you to do this. See your device manufacturer's
                instructions for further details.
              </span>
            </li>
          </ul>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">&nbsp;Information from Third Parties</span>
          </p>
          <p className="c6">
            <span className="c3">
              We may collect, process and store your user ID associated with any
              social media account (such as your Facebook and Google account)
              that you use to sign into the Services or connect with or use with
              the Services. When you sign in to your account with your social
              media account information, or otherwise connect to your social
              media account with the Services, you consent to our collection,
              storage, and use, in accordance with this Privacy Policy, of the
              information that you make available to us through the social media
              interface. This could include, without limitation, any information
              that you have made public through your social media account,
              information that the social media service shares with us, or
              information that is disclosed during the sign-in process. Please
              see your social media provider's privacy policy and help center
              for more information about how they share information when you
              choose to connect your account.
            </span>
          </p>
          <p className="c14 c9">
            <span className="c3">
              We may also obtain information about you from third parties such
              as partners, marketers, third-party websites, and researchers, and
              combine that information with information which we collect from or
              about you.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">Anonymous or De-Identified Data</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              We may anonymize and/or de-identify information collected from you
              through the Services or via other means, including via the use of
              third-party web analytic tools as described below. As a result,
              our use and disclosure of aggregated and/or de-identified
              information is not restricted by this Privacy Policy, and it may
              be used and disclosed to others without limitation.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">How we use the information we collect</span>
          </p>
          <p className="c6">
            <span className="c3">
              We use the information we collect from and about you for a variety
              of purposes, including to:
            </span>
          </p>
          <ul className="c19 lst-kix_list_7-0 start">
            <li className="c23 c9">
              <span className="c3">Process and respond to your queries</span>
            </li>
            <li className="c2">
              <span className="c3">
                Understand our users (what they do on our Services, what
                features they like, how they use them, etc.), improve the
                content and features of our Services (such as by personalizing
                content to your interests), process and complete your
                transactions, and make special offers.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Administer our Services and diagnose technical problems.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Send you communications that you have requested or that may be
                of interest to you by way of emails, or courier, or registered
                post, or telephone calls, or any other mode of communication. We
                may also share your preferences or the Services availed by you
                with your network followers on Crewbella for marketing and other
                promotional activities of our Services.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Send you questions from other users that you may be able to
                answer if you have registered with Crewbella
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Enable us to show you ads that are relevant to you.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Generate and review reports and data about, and to conduct
                research on, our user base and Service usage patterns.
              </span>
            </li>
            <li className="c2">
              <span className="c3">Administer contests and sweepstakes.</span>
            </li>
            <li className="c2">
              <span className="c3">Provide you with customer support.</span>
            </li>
            <li className="c2">
              <span className="c3">
                Provide you with policies about your account.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Notify you about changes to our Services.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                Allow you to participate in interactive features offered through
                our Services.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                In any other way we may describe when you provide the
                information.
              </span>
            </li>
            <li className="c12 c9">
              <span className="c3">
                For any other purpose with your consent.
              </span>
            </li>
          </ul>
          <p className="c1">
            <span className="c3">
              We may also use your information to contact you about our own and
              third-party goods and services that may be of interest to you. If
              you do not want us to use your information in this way, please
              check the relevant box located on the form on which we collect
              your data and/or adjust your user preferences in your account
              profile.
            </span>
          </p>
          <p className="c9 c14">
            <span className="c3">
              We may use the information we have collected from you to enable us
              to display advertisements to our advertisers'/service providers'
              target audiences. Even though we do not disclose your personal
              information for these purposes without your consent, if you click
              on or otherwise interact with an advertisement, the advertiser may
              assume that you meet its target criteria.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;How we share the information we collect
            </span>
          </p>
          <p className="c6">
            <span className="c3">
              We may disclose personal information that we collect or you
              provide, as described in this privacy policy, in the following
              ways:
            </span>
          </p>
          <p className="c13 c9">
            <span className="c5">General Information Disclosures</span>
          </p>
          <ul className="c19 lst-kix_list_8-0 start">
            <li className="c23 c9">
              <span className="c3">
                To our subsidiaries and affiliates, which are entities under
                common ownership or control of our company Crewbella.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                To contractors, advertisers/service providers and other
                third-parties whom we use to support our business (e.g. Contract
                registration and event or competition participation) and who are
                bound by contractual obligations to keep personal information
                confidential and use it only for the purposes for which we
                disclose it to them.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution or other
                sale or transfer of some or all of Crewbella’s assets, whether
                as a going concern or as part of bankruptcy, liquidation or
                similar proceeding, in which personal information held by
                Crewbella about the users of our Services are among the assets
                transferred.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                To third-parties to market their products or services to you if
                you have consented to receive the promotional updates. We
                contractually require these third-parties to keep personal
                information confidential and use it only for the purposes for
                which we disclose it to them.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                To fulfill the purpose for which you provide it.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                For any other purpose disclosed by us when you provide the
                information.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Service Providers.</span>
              <span className="c3">
                &nbsp;We may share your information with outside vendors that we
                use for a variety of purposes, such as to send you
                communications via emails, messages or tele-call to inform you
                about our products that may be of interest to you, push
                notifications to your mobile device on our behalf, provide voice
                recognition services to process your spoken queries and
                questions, help us analyze use of our Services, and process and
                collect payments. Some of our products, services and databases
                are hosted by third party hosting services providers. We also
                may use vendors for other projects, such as conducting surveys
                or organizing sweepstakes for us. We may share information about
                you with these vendors only to enable them to perform their
                services.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Legal Purposes.</span>
              <span className="c3">
                &nbsp;We may share your information when we believe in good
                faith that such sharing is reasonably necessary in order to
                investigate, prevent, or take action regarding possible illegal
                activities or to comply with legal process. We may also share
                your information to investigate and address threats or potential
                threats to the physical safety of any person, to investigate and
                address violations of this Privacy Policy or the Terms of
                Service, or to investigate and address violations of the rights
                of third parties and/or to protect the rights, property and
                safety of Crewbella, our employees, users, or the public. This
                may involve the sharing of your information with law
                enforcement, government agencies, courts, and/or other
                organizations on account of legal request such as subpoena,
                court order or government demand to comply with the law.
              </span>
            </li>
            <li className="c2">
              <span className="c7">Social Networks.</span>
              <span className="c3">
                &nbsp;If you interact with social media features on our
                Services, such as the Facebook Like button, or use your social
                media credentials to log-in or post content, these features may
                collect information about your use of the Services, as well as
                post information about your activities on the social media
                service. Your interactions with social media companies are
                governed by their privacy policies.
              </span>
            </li>
            <li className="c2">
              <span className="c15">To enforce or apply our Terms of Use </span>
              <span className="c20">[www.crewbella.com/conditions</span>
              <span className="c3">
                ] and other agreements, including for billing and collection
                purposes.
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of Crewbella, our users or
                others. This includes exchanging information with other
                companies and organizations for the purposes of fraud protection
                and credit risk reduction.
              </span>
            </li>
            <li className="c12 c9">
              <span className="c7">Consent.</span>
              <span className="c3">
                &nbsp;We may share your information in any other circumstances
                where we have your consent.
              </span>
            </li>
          </ul>
          <p className="c9 c31">
            <span className="c5">Payment Card Information</span>
          </p>
          <p className="c6">
            <span className="c3">
              To use certain of our Services, such as to pay the crew or take
              payment and to make payments to, we may require credit or debit
              card account information. By submitting your credit or debit card
              account information through our Services, you expressly consent to
              the sharing of your information with restaurants, third-party
              payment processors, and other third-party service providers
              (including but not limited to vendors who provide fraud detection
              services to us and other third parties), and you further agree to
              the following terms:
            </span>
          </p>
          <ul className="c19 lst-kix_list_9-0 start">
            <li className="c23 c9">
              <span className="c3">
                When you use a credit or debit card to secure a reservation
                through our Sites, we provide your credit or debit card account
                information (including card number and expiration date) to our
                third-party payment service providers..
              </span>
            </li>
            <li className="c2">
              <span className="c3">
                When you initially provide your credit or debit card account
                information through our Services in order to use our services,
                we provide your credit or debit card account information to our
                third-party payment service providers. As explained in our Terms
                of Use, these third parties may store your credit or debit card
                account information so you can use our restaurant payment
                services through our Services in the future.
              </span>
            </li>
            <li className="c12 c9">
              <span className="c3">
                For information about the security of your credit or debit card
                account information, see the "Security" section below.
              </span>
            </li>
          </ul>
          <p className="c31 c9">
            <span className="c5">Tailored Advertising</span>
          </p>
          <p className="c6">
            <span className="c3">
              Third parties whose products or services are accessible or
              advertised via the Services may also use cookies or similar
              technologies to collect information about your use of the
              Services. This is done in order to help them-
            </span>
          </p>
          <ul className="c19 lst-kix_list_10-0 start">
            <li className="c23 c9">
              <span className="c3">
                Inform, optimize, and serve ads based on past visits to our
                website and other sites and
              </span>
            </li>
            <li className="c34 c9">
              <span className="c3">
                Report how our ad impressions, other uses of ad services, and
                interactions with these ad impressions and ad services are
                related to visits to our website. We also allow other third
                parties (e.g., ad networks and ad servers such as Google
                Analytics, OpenX, Pubmatic, DoubleClick and others) to serve
                tailored ads to you on the Services, and to access their own
                cookies or similar technologies on your computer, mobile phone,
                or other device you use to access the Services. We neither have
                access to, nor does this Privacy Policy govern, the use of
                cookies or other tracking technologies that may be placed by
                such third parties. These parties may permit you to opt out of
                ad targeting. If you are interested in more information about
                tailored browser advertising and how you can generally control
                cookies from being put on your computer to deliver tailored
                advertising (i.e., not just for the Services), you may visit the
                Network Advertising Initiative's Consumer Opt-Out Link, and/or
                the Digital Advertising Alliance's Consumer Opt-Out Link to
                opt-out of receiving tailored advertising from companies that
                participate in those programs. To opt out of Google Analytics
                for Display Advertising or customize Google Display Network ads,
                you can visit the Google Ads Settings page. Please note that to
                the extent advertising technology is integrated into the
                Services, you may still receive ads even if you opt-out of
                tailored advertising. In that case, the ads will just not be
                tailored to your interests. Also, we do not control any of the
                above opt-out links and are not responsible for any choices you
                make using these mechanisms or the continued availability or
                accuracy of these mechanisms.
              </span>
            </li>
          </ul>
          <p className="c16 c9">
            <span className="c3">
              When accessing the Services from a mobile application you may also
              receive tailored in-application advertisements. Each operating
              system: iOS, Android and Windows Phone provides its own
              instructions on how to prevent the delivery of tailored
              in-application advertisements. You may review the support
              materials and/or the privacy settings for the respective operating
              systems in order to opt-out of tailored in-application
              advertisements. For any other devices and/or operating systems,
              please visit the privacy settings for the applicable device or
              operating system or contact the applicable platform operator.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;Choices about how we use and disclose your information
            </span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              We strive to provide you with choices regarding the personal
              information you provide to us. You can set your browser or mobile
              device to refuse all or some browser cookies, or to alert you when
              cookies are being sent. To learn how you can manage your Flash
              cookie settings, visit the Flash player settings page on Adobe's
              website. If you disable or refuse cookies, please note that some
              parts of our Services may then be inaccessible or not function
              properly. We do not share your personal information with any
              advertising agency.
            </span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">Communication choices</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              When you sign up for an account, you are opting in to receive
              emails from other Crewbella users, businesses, and Crewbella
              itself. You can log in to manage your email preferences and follow
              the "unsubscribe" instructions in commercial email messages, but
              note that you cannot opt out of receiving certain administrative
              policy, service policy, or legal policy from Crewbella.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;Reviewing, changing or deleting information
            </span>
          </p>
          <p className="c6">
            <span className="c3">
              If you would like to review, change or delete personal information
              we have collected from you, or permanently delete your account,
              please use the "Contact Us" link at the bottom of every page (also
              located here), or contact the Crewbella Data Protection Officer
              (DPO).
            </span>
          </p>
          <p className="c14 c9">
            <span className="c15">
              If you delete your User Contributions from our websites, copies of
              your User Contributions may remain viewable in cached and archived
              pages, or might have been copied or stored by other users of our
              websites. Proper access and use of information provided on our
              websites, including User Contributions, is governed by our Terms
              of Use [
            </span>
            <span className="c20 c30">
              <a
                className="c25"
                href="https://www.google.com/url?q=http://www.crewbella.com/conditions&amp;sa=D&amp;ust=1608810810008000&amp;usg=AOvVaw1glHQt31qmHi1uOp2veL6A"
              >
                www.crewbella.com/conditions
              </a>
            </span>
            <span className="c3">]</span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;Accessing &amp; correcting your personal information
            </span>
          </p>
          <p className="c6">
            <span className="c3">
              We will take reasonable steps to accurately record the personal
              information that you provide to us and any subsequent updates.
            </span>
          </p>
          <p className="c6">
            <span className="c3">
              We encourage you to review, update, and correct the personal
              information that we maintain about you, and you may request that
              we delete personal information about you that is inaccurate,
              incomplete, or irrelevant for legitimate purposes, or are being
              processed in a way which infringes any applicable legal
              requirement.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3">
              <br />
            </span>
          </p>
          <p className="c6">
            <span className="c3">
              Your right to review, update, correct, and delete your personal
              information may be limited, subject to the law of your
              jurisdiction:
            </span>
          </p>
          <ul className="c19 lst-kix_list_11-0 start">
            <li className="c23 c9">
              <span className="c3">
                If your requests are abusive or unreasonably excessive,
              </span>
            </li>
            <li className="c22 c9">
              <span className="c3">
                Where the rights or safety of another person or persons would be
                encroached upon, or
              </span>
            </li>
            <li className="c9 c22">
              <span className="c3">
                If the information or material you request relates to existing
                or anticipated legal proceedings between you and us, or
                providing access to you would prejudice negotiations between us
                or an investigation of possible unlawful activity. Your right to
                review, update, correct, and delete your information is subject
                to our records retention policies and applicable law, including
                any statutory retention requirements.
              </span>
            </li>
            <li className="c13 c17 c9">
              <span className="c8">
                Security: How we protect your information
              </span>
            </li>
            <li className="c14 c9 c17">
              <span className="c3">
                We have implemented appropriate physical, electronic, and
                managerial procedures to safeguard and help prevent unauthorized
                access to your information and to maintain data security. These
                safeguards take into account the sensitivity of the information
                that we collect, process and store and the current state of
                technology. We follow generally accepted industry standards to
                protect the personal information submitted to us, both during
                transmission and once we receive it. The third party service
                providers with respect to payment gateway and payment processing
                are all validated as compliant with the payment card industry
                standard (generally referred to as PCI compliant service
                providers).
              </span>
            </li>
            <li className="c14 c17 c9">
              <span className="c3">
                We assume no liability or responsibility for disclosure of your
                information due to errors in transmission, unauthorized
                third-party access, or other causes beyond our control. You play
                an important role in keeping your personal information secure.
                You should not share your user name, password, or other security
                information for your Crewbella account with anyone. If we
                receive instructions using your user name and password, we will
                consider that you have authorized the instructions.
              </span>
            </li>
          </ul>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">Permissible Age</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              The Services are not intended for users under the age of 13,
              unless permitted under applicable local laws (Permissible Age). We
              do not knowingly collect any personal information from users or
              market to or solicit information from anyone under the Permissible
              Age. If we become aware that a person submitting personal
              information is under the Permissible Age, we will delete the
              account and any related information as soon as possible. If you
              believe we might have any information from or about a user under
              the Permissible Age, please contact us.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">&nbsp;Third party links and services</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              The Services may contain links to third-party websites. Your use
              of these features may result in the collection, processing or
              sharing of information about you, depending on the feature. Please
              be aware that we are not responsible for the content or privacy
              practices of other websites or services which may be linked on our
              services. We do not endorse or make any representations about
              third-party websites or services. Our Privacy Policy does not
              cover the information you choose to provide to or that is
              collected by these third parties. We strongly encourage you to
              read such third parties' privacy policies.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">California privacy rights</span>
          </p>
          <p className="c13 c9">
            <span className="c15">
              California law permits users who are California residents to
              request and obtain from us once a year, free of charge, a list of
              the third parties to whom we have disclosed their personal
              information (if any) for their direct marketing purposes in the
              prior calendar year, as well as the type of personal information
              disclosed to those parties. To make such a request, please contact
              us at the information provided in&nbsp;
            </span>
            <span className="c7">CONTACT US</span>
            <span className="c3">&nbsp;section.</span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c6">
            <span className="c9 c32">EU privacy/data protection rights</span>
          </p>
          <p className="c6">
            <span className="c3">
              EU General Data Protection Regulation (GDPR) has provided the
              below rights to the EU residents:
            </span>
          </p>
          <ul className="c19 lst-kix_list_12-0 start">
            <li className="c23 c9">
              <span className="c7">Right to information</span>
              <span className="c3">
                &nbsp;- including contact details to the DPO, the purposes for
                processing Personal Information and the rights of the
                individual.
              </span>
            </li>
            <li className="c22 c9">
              <span className="c7">Right to access</span>
              <span className="c3">
                &nbsp;the Personal Information that are processed
              </span>
            </li>
            <li className="c22 c9">
              <span className="c7">Right to erasure</span>
              <span className="c3">&nbsp;(”Right to be forgotten”)</span>
            </li>
            <li className="c22 c9">
              <span className="c7">Right to rectification</span>
            </li>
            <li className="c22 c9">
              <span className="c7">Right to restriction of processing</span>
            </li>
            <li className="c22 c9">
              <span className="c7">Right to data portability</span>
              <span className="c3">
                &nbsp;of the Personal Information supplied to Crewbella by the
                EU resident
              </span>
            </li>
            <li className="c9 c34">
              <span className="c7">Right to object</span>
              <span className="c3">
                &nbsp;(opt-out) to processing (unless Crewbella otherwise has
                compelling legitimate grounds)
              </span>
            </li>
          </ul>
          <p className="c14 c9">
            <span className="c15">
              EU residents can exercise these rights by raising a request
              directly at privacy@crewbella.com or contact us at the information
              provided in&nbsp;
            </span>
            <span className="c7">CONTACT US</span>
            <span className="c3">&nbsp;section.</span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">
              &nbsp;Data retention and account termination
            </span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              You can close your account by visiting your profile settings page
              on our website. We will remove your public posts from view and/or
              dissociate them from your account profile, but we may retain
              information about you for the purposes authorized under this
              Privacy Policy unless prohibited by law. Thereafter, we will
              either delete your personal information or de-identify it so that
              it is anonymous and not attributed to your identity. For example,
              we may retain information to prevent, investigate, or identify
              possible wrongdoing in connection with the Service or to comply
              with legal obligations.
            </span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">Job applicants</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              If your information is submitted to us through our Service when
              applying for a position with our company, the information will be
              used to consider your application. We may retain your information
              for any period of time. This information may be shared with other
              companies for the purpose of evaluating your qualifications for
              the particular position or other available positions, as well as
              with third-party service providers retained by us to collect,
              maintain and analyze candidate submissions for job postings.{" "}
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c13 c9">
            <span className="c8">Changes to this privacy policy</span>
          </p>
          <p className="c13 c9">
            <span className="c3">
              We reserve the right to amend this Privacy Policy from time to
              time to reflect changes in the law, our data collection and use
              practices, the features of our services, or advances in
              technology. Please check this page periodically for changes. Use
              of information we collect is subject to the Privacy Policy in
              effect at the time such information is used. If we make any
              material changes to this Privacy Policy, we will post the changes
              here. Please review the changes carefully. Your continued use of
              the Services following the posting of changes to this Privacy
              Policy will constitute your consent and acceptance of those
              changes.
            </span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c9 c27">
            <span className="c8">Contact us</span>
          </p>
          <p className="c6">
            <span className="c15">
              If you have any queries relating to the processing/ usage of
              information provided by you or Crewbella’s Privacy Policy, you may
              email the Data Protection Officer (DPO) at&nbsp;
            </span>
            <span className="c20 c30">info@crewbella.com</span>
            <span className="c3">
              &nbsp;or write to us at the following address.
            </span>
          </p>
          <p className="c6 c11">
            <span className="c3"></span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c10 c9">
            <span className="c3"></span>
          </p>
          <p className="c0">
            <span className="c3"></span>
          </p>
          <p className="c9 c10">
            <span className="c3"></span>
          </p>
          <p className="c28 c11">
            <span className="c18"></span>
          </p>
        </div>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicy;
