/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
  Divider,
  ModalOverlay,
  Circle,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  ModalFooter,
  Text,
  Select,
  FormHelperText,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useDisclosure ,useContext} from "react";
import { CBColors, createLeadsApi } from "../../config";
import { BsGoogle } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { render } from "@testing-library/react";
import RequirementsList from "../../data/dropdown";
import { SingleDatepicker  } from "chakra-dayzed-datepicker";
import { format, addYears, addDays } from "date-fns";
import { Context } from "../../provider/context";
import moment from 'moment';
import axios from "axios";

const LeadPostingModal = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isInPerson, setIsInPerson] = useState(false);
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [budget, setBudget] = useState("");
  const [lookingFor, setlookingFor] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  const dayBeforeToday = addDays(today, -1);
  const maxDate = addYears(today, 77);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const { loginStatus, appliedData, createdData, appliedModalStatus,appliedModelText,appliedModelLink,userInfo } =
    useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [isModalAppliedOpen, setisModalAppliedOpen] = appliedModalStatus;
  const [modalAppliedText, setModalAppliedText] = appliedModelText;
  const [modalAppliedLink, setModalAppliedLink] = appliedModelLink;
  const [userIdDB, setUserIdDB]= userInfo;

 

  const onSubmit =async () => {
    console.log("submit");
    console.log("lookingFor", lookingFor);
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    console.log("isInPerson", isInPerson);
    console.log("location", location);
    console.log("description", description);
    console.log("budget", budget);
    //api call
    await axios.post(createLeadsApi, {
      'lookingFor': lookingFor,
        'description': description,
        'location': location,
        'duration': {
          'startDate': startDate,
          'endDate': endDate,
        },
        'createdBy': userIdDB,
        'budget': budget,
        'broker': 'app'
    }).then((res) => {
      if (res.status === 201) {
        toast({
          title: res.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        var d = JSON.decode(localStorage.getItem("applied"));
        d.push(res.data.data.leadsApplied);
        localStorage.setItem(
          "created",
          JSON.stringify(d)
        );
        setLoggedIn(true);
        setApplied(res.data.data.leadsApplied);
        setCreated(res.data.data.leadsCreated);
        onClose();
        setIsLoading(false);
      } else {
        toast({
          title: res.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    })
    .catch((err) => {
      if (err.response.status === 404) {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      } else {
        toast({
          description: "Something went wrong. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }});
    
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent zIndex="9999" borderRadius="20px" bg="white">
        <ModalCloseButton />

        <ModalBody mt={"30px"}>
          <Text fontSize={"12px"} color={"#828282"}>
            Important information about the work your hiring for
          </Text>
          <form>
            <Select
              placeholder="whom are you looking for"
              borderRadius={20}
              borderColor="transparent"
              backgroundColor="#FFF9F9"
              onChange={(e)=>{
                
                setlookingFor(e.target.value)
                
              }}
            >
              backgroundColor="#FFF9F9">
              {RequirementsList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            <Box p={4}>
              <FormControl as="fieldset">
                <FormLabel as="legend" fontSize={"12px"} color={"#828282"}>
                  What place do they have to work?:
                </FormLabel>
                <RadioGroup
                >
                  <Stack direction="row">
                    <Radio value="remote" onChange={()=>{
                      setLocation("remote")
                      setIsInPerson( false );
                      console.log(location)
                    }}>Remote</Radio>
                    <Radio value="inPerson"  onChange={()=>{
                      setIsInPerson( true );
                    }}>In Person</Radio>
                    
                  </Stack>
                </RadioGroup>
              </FormControl>
              {isInPerson && (
                <FormControl
                  id="location"
                  borderRadius={20}
                  backgroundColor="#FFF9F9"
                  mb={"16px"}
                  mt={"16px"}
                  onChange={(e)=>{
                    setLocation(e.target.value)
                   
                  }}
                >
                  <Input
                    variant="flushed"
                    placeholder="Location"
                    _placeholder={{ color: "#A9A9A9" }}
                    px={4}
                    py={2}
                    _focus={{ borderColor: "transparent", boxShadow: "none" }}
                    borderColor="transparent"
                  />
                </FormControl>
              )
              }
            </Box>
            <Stack direction="row" mb={"16px"}>
              <FormControl>
                <FormLabel fontSize={"12px"} color={"#828282"}>
                  Start Date
                </FormLabel>
                <SingleDatepicker
                  minDate={dayBeforeToday}
                  maxDate={maxDate}
                  // dateFormat={"MMM dd, yyyy"}
                  onDateChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))}
                  closeOnSelect={true}
                  render={({ inputProps, calendarProps }) => (
                    <Input
                      {...inputProps}
                      defaultValue={startDate ? moment(startDate).format('MMM, DD, YYYY') : ""}
                      onClick={calendarProps.onOpen}
                      borderRadius={"20px"}
                      borderColor="transparent"
                      backgroundColor="#FFF9F9"
                    />
                  )}
                />
              </FormControl>
              <Divider orientation="vertical" />
              <FormControl>
                <FormLabel fontSize={"12px"} color={"#828282"}>
                  End Date
                </FormLabel>
                <SingleDatepicker
                  minDate={today}
                  maxDate={maxDate}
                  dateFormat={"MMM dd, yyyy"}
                  onDateChange={(date) => setEndDate(moment(date).format('YYYY-MM-DD'))}
                  render={({ inputProps, calendarProps }) => (
                    <Input
                      {...inputProps}
                      defaultValue={endDate ? moment(endDate).format('MMM, DD, YYYY') : ""}
                      onClick={calendarProps.onOpen}
                      borderRadius={"20px"}
                      borderColor="transparent"
                      backgroundColor="#FFF9F9"
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <FormControl
              id="desciption"
              borderRadius={20}
              backgroundColor="#FFF9F9"
              mb={"16px"}
              onChange={(e)=>{
                setDescription(e.target.value)
              }}
            >
              <Input
                variant="flushed"
                placeholder="Give a short Description of thr work needed"
                h={"100px"}
                _placeholder={{ color: "#A9A9A9" }}
                px={4}
                py={2}
                _focus={{ borderColor: "transparent", boxShadow: "none" }}
                borderColor="transparent"
              />
            </FormControl>
            <FormControl
              id="budget"
              borderRadius={20}
              backgroundColor="#FFF9F9"
              mb={"16px"}
              onChange={(e)=>{
                setBudget(e.target.value)
              }}
            >
              <Input
                variant="flushed"
                placeholder="Total Budget Range"
                _placeholder={{ color: "#A9A9A9" }}
                px={4}
                py={2}
                _focus={{ borderColor: "transparent", boxShadow: "none" }}
                borderColor="transparent"
              />
            </FormControl>
          </form>
          <Box display="flex" justifyContent="center">
            <Button
              color={CBColors.textBox}
              bg={CBColors.wait}
              borderRadius={"20px"}
              w={"161px"}
              onClick={onSubmit}
            >
              Post requirement
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LeadPostingModal;
