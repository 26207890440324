import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import childTop from "../../assets/images/home_image_boy.png";
import childBottom from "../../assets/images/home_image_girl.png";
import FAQPage from "./faq_page";

const LeftHomePage = () => {
  return (
    <Flex flexDirection="column">
      <Box>
        <Flex>
          <Image src={childTop} alt="boy" w="109px" h="159px" />
          <Flex flexDirection="column">
            <Text
              fontSize="24px"
              fontWeight="bold"
              fontFamily="Roboto"
              paddingTop="50px"
              marginLeft="10px"
            >
              What the hell is crewbella?
            </Text>
            <Text
              fontSize="20px"
              fontFamily="Roboto"
              paddingTop="21px"
              marginLeft="10px"
              w="300px"
            >
              Nothing bro, just building trust between client & crew for the
              money and work they put at risk.
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Flex>
          <Flex flexDirection="column" paddingLeft="20px">
            <Text
              fontSize="24px"
              fontWeight="bold"
              fontFamily="Roboto"
              paddingTop="58px"
            >
              And how do we do it?
            </Text>
            <Text
              fontSize="20px"
              fontFamily="Roboto"
              paddingTop="21px"
              w="330px"
            >
              We hold the money for the client against the crew and on
              completion of work automatically release it and in every other
              case settle it accordingly.
            </Text>
          </Flex>
          <Image src={childBottom} alt="girl" w="109px" h="186px" />
        </Flex>
      </Box>
      <Box mt={"20px"}>
      <FAQPage/>
      </Box>
    </Flex>
  );
};

export default LeftHomePage;
