import { Box, Flex, Text, Heading, Image, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
import LeftHomePage from "./left_page";
import RightHomePage from "./right_page";
import { CBColors } from "../../config";
import Header from "../../components/core/header";

import Footer from "../../components/core/footer";

const Homepage = () => {
  const navigate = useNavigate();

  return (
    <Flex flexDirection="column" bg={CBColors.bg}>
      <Flex paddingX="30px" flexDirection="column">
        {/* header */}
        <Header />
        {/* bottom portion */}
        <Flex justifyContent={"space-between"}>
          {/* left side */}
          <Box p="10px" h="calc(100vh - 60px)" overflow="auto">
            <LeftHomePage />
          </Box>
          {/* right side */}
          <Box w="calc(100% - 500px)" h="100vh" overflow="auto">
            <RightHomePage/>
          </Box>
        </Flex>
      </Flex>
      {/* footer */}
      {<Footer />}
    </Flex>
  );
};

export default Homepage;
