import React from "react";
import {
  Flex,
  Text,
  Image,
  Divider,
  Button,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import logoImage from "../../assets/images/logo-text.png";
import { CBColors } from "../../config";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <Flex bg={CBColors.footer} px="20px" py={"15px"} flexDirection={"column"}>
      <Flex
        justifyContent="space-between"
        align="center"
        mb={"10px"}
        px={"10px"}
      >
        <Flex flexDirection="column" alignItems="start">
          <Image
            src={logoImage}
            alt="Logo"
            w="151.91px"
            h="60.5px"
            marginRight="210px"
          />
          <Text color={CBColors.textBox} fontSize={"12"} fontWeight={"400"}>
            A-541 Chandravardai Nagar, Ajmer Rajasthan 305003
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="end">
          <HStack spacing={2} mt={"5px"} mb={"5px"}>
            <IconButton
              aria-label="Facebook"
              icon={<FaFacebook />}
              borderRadius="full"
              size="xs"
              fontSize="15px"
              bg="white"
              onClick={() => {
                window.open("https://facebook.com/crew.bella.9", "_blank")
              }}
            />
            <IconButton
              aria-label="LinkedIn"
              icon={<FaLinkedin />}
              borderRadius="full"
              size="xs"
              fontSize="15px"
              bg="white"
              onClick={() => {
                window.open("https://www.linkedin.com/company/crewbella/", "_blank")
              }}
            />
            <IconButton
              aria-label="Twitter"
              icon={<FaTwitter />}
              borderRadius="full"
              size="xs"
              fontSize="15px"
              bg="white"
              onClick={() => {
                window.open("https://twitter.com/CrewBella1", "_blank")
              }}
            />
            <IconButton
              aria-label="Instagram"
              icon={<FaInstagram />}
              borderRadius="full"
              size="xs"
            
              fontSize="15px"
              bg="white"
              onClick={() => {
                window.open("https://www.instagram.com/crewbella_/", "_blank")
              }}
            />
          </HStack>

          <Text color={CBColors.textBox} fontSize={"12px"} fontWeight={"400"}>
            info@crewbella.com
          </Text>
          <Text color={CBColors.textBox} fontSize={"12px"} fontWeight={"400"}>
            +91 78776 57656
          </Text>
        </Flex>
      </Flex>
      <Divider
        color={CBColors.faded}
        borderWidth="0.1px"
        size="lg"
        w="100%"
        mb={"5px"}
      />
      <Flex justifyContent="space-between" align="center" px={"10px"}>
        <Text color={CBColors.textBox} fontSize={"12px"} fontWeight={"400"}>
          © 2023 CrewBella Ecosystems. All rights reserved.
        </Text>
        <Flex justifyContent="end" align="center">
          <Button
            variant={"link"}
            color={CBColors.textBox}
            fontSize={"12px"}
            fontWeight={"400"}
            mr={"5px"}
            onClick={() => {
              navigate("/terms-conditions");
            }}
          >
            Terms of Use
          </Button>
          <Divider
            color={CBColors.faded}
            borderWidth=".1px"
            orientation="vertical"
            h="10px"
          />
          <Button
            variant={"link"}
            color={CBColors.textBox}
            fontSize={"12px"}
            fontWeight={"400"}
            ml={"5px"}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
