import Lottie from "react-lottie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Heading,
  Image,
  Button,
  VStack,
} from "@chakra-ui/react";
import animationData from "../../assets/anims/no_leads.json";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    
      <Flex flexDirection={"column"} align={"center"} justifyContent="center"
      height="100vh" alignItems="center">
        <Box alignItems="center">
          <Lottie
            options={{
              animationData: animationData,
              loop: true,
              autoplay: true,
            }}
            width={400}
            height={400}
            
            style={{ margin: "auto" }}
          />

          <Heading
            as="h1"
            size="2xl"
            textAlign="center"
            marginBottom="20px"
          >
            Oops! Page not found
          </Heading>
          <Text textAlign="center" fontSize="xl">
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </Text>

          <Button
            bg="red"
            color="white"
            marginTop="20px"
            marginBottom="20px"
            marginLeft="auto"
            marginRight="auto"
            display="block"
            onClick={() => {
              navigate("/", { replace: true });
            }}
            _hover={{ bg: "red", color: "white" }}
          >
            Go to Home
          </Button>
        </Box>
      </Flex>
  );
};

export default ErrorPage;
