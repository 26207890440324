import React from 'react';
import logo from '../../assets/images/logo.png';
import './SplashScreen.css';

function SplashScreen() {
  return (
    <div className="splash-screen">
      <img src={logo} className="logo" alt="logo" />
    </div>
  );
  
}

export default SplashScreen;
