/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Image,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CBColors } from "../../config";
import { BsGoogle } from "react-icons/bs";
import { useForm } from "react-hook-form";
import appliedGif from "../../assets/gifs/applied.gif";

const AppliedModal = ({ isOpen, onClose, text, link }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    console.log(data);
    setIsSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent
        zIndex="9999"
        borderRadius="20px"
        bg="white"
        position="relative"
      >
        <ModalCloseButton />
        <ModalBody>
          <Stack width="368px" height={link !== undefined ?"400px" : "358px"} maxWidth="100%">
            <Box
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              borderRadius="20px"
              width="368px"
              maxWidth="100%"
              background={CBColors.ImEverywhere}
              boxShadow="morphic shadow"
              mt={"20px"}
              ml={"16px"}
            >
              <Image
                src={appliedGif}
                alt="My GIF"
                height={"300px"}
                width={"368px"}
                maxWidth="100%"
                borderRadius="20px"
                align={"center"}
                alignSelf={"center"}
              />
            </Box>
            <Text
              fontFamily="Roboto"
              fontWeight="medium"
              fontSize="16px"
              color="CrewbellaColor.read"
              textAlign="center"
              alignSelf={"center"}
              ml={"16px"}
              mt={"8px"}
            >
              {text}
            </Text>
            {link !== undefined && (
            <Button
              alignSelf={"center"}
              color={CBColors.textBox}
              bg={CBColors.wait}
              borderRadius={"20px"}
              w={"131px"}
              onClick={() => {
                window.open(link, "_blank");
              }}
            >
              Download App
            </Button>
            )}
            <Box
              borderRadius="20px"
              width="368px"
              height="216px"
              maxWidth="100%"
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AppliedModal;
