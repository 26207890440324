import React from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  useMediaQuery,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import { CBColors } from "../../config";
import PublicCard from "../../components/cards/public_card";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { getALlLeadsApi } from "../../config";
import { Context } from "../../provider/context";

const RightHomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState([]);
  const { loginStatus, appliedData, createdData, appliedModalStatus } =
    useContext(Context);
  const [loggedIn, setLoggedIn] = loginStatus;
  const [applied, setApplied] = appliedData;
  const [created, setCreated] = createdData;
  const [isModalAppliedOpen, setisModalAppliedOpen] = appliedModalStatus;
  const [page, setPage] = useState(1);

  const toast = useToast();
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(getALlLeadsApi + `/?page=${page}`)
      .then((response) => {
        var dd = response.data.data.data.leads;
        if (loggedIn) {
          dd = dd.filter((item) => !created.includes(item._id));
        }
        setData(dd);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: "No leads found",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page,data]);
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight && hasNextPage) {
      axios
        .get(getALlLeadsApi + `/?page=${page}`)
        .then((response) => {
          var dd = response.data.data.data.hasNextPage;
          if (dd === true) {
            setPage(page + 1);
            console.log(page);
            axios
              .get(getALlLeadsApi + `/?page=${page}`)
              .then((response) => {
                var dd = response.data.data.data.leads;
                if (loggedIn) {
                  dd = dd.filter((item) => !created.includes(item._id));
                }
                var dd4 = [...data, ...dd];
                setData(dd4);
              })
              .catch((error) => {
                console.log(error);
                toast({
                  description: "No leads found",
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                });
              });
          }else{
            setHasNextPage(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            description: "No leads found",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const [isLargerThan1970] = useMediaQuery("(min-width: 1970px)");

  return (
    <Flex flexDirection="column" alignItems={"center"}>
      <Box marginBottom={"80px"}>
        <Text
          color={CBColors.go}
          fontSize="32px"
          fontStyle="italic"
          fontFamily="Roboto"
          fontWeight="800"
          align="center"
        >
          New Opportunities
        </Text>
       
        {/* <Divider
          color={CBColors.faded}
          borderWidth="1px"
          size="lg"
          w="376.5px"
        /> */}
         <Text
          color={"grey"}
          fontSize="12px"
          fontStyle="italic"
          fontFamily="Roboto"
          fontWeight="400"
          align="center"
        >
          
          opportunities older than 1 months are automatically deactivated
        </Text>
        <Divider
          color={CBColors.faded}
          borderWidth="1px"
          size="lg"
          w="376.5px"
        />
      </Box>
      {/* center leads */}
      {isLoading ? (
        <CircularProgress
          isIndeterminate
          color="blue.500"
          display={"flex"}
          justifyContent={"center"}
        />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${isLargerThan1970 ? 3 : 2}, 1fr)`,
            rowGap: "50px",
            columnGap: "76px",
          }}
        >
          {data.map((lead, index) => (
            <PublicCard key={index} data={lead} />
          ))}
        </div>
      )}
    </Flex>
  );
};

export default RightHomePage;
